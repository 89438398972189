import Footer from "./Footer";
import Navbar from "./Navbar";

import glass1 from "../assests/images/glass1.jpg";
import Fadeimg from "../pages/FadeOnimg/Fadeimg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function GlassPlant() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scroll(0, -100)
  }, [pathname])
  return (
    <>
      <Navbar />
      <div className={`mt-[150px] sm:!mt-[100px] flex items-center justify-center flex-col`}>
        <h1 className={`w-full h-auto flex items-center justify-center`}>
          <span className={`w-auto h-auto text-xl border-b-2 border-b-[#4c4c4c] px-[8px]`}>
            GLASS
          </span>
        </h1>
        <div className={`my-[10px]`}>
          <Fadeimg>
            <img className={`border w-[300px] h-auto sm:!w-[300px] sm:h-auto `} src={glass1} alt="" />
          </Fadeimg>
        </div>
        <div className={`px-[30px] py-[15px]`}>
          <p>
            CCPL provides a wide range of Fireclay, High Alumina, Magnesia &
            various monolithic refractories for glass tank furnace application.
            These products are well engineered by high quality raw materials
            coupled with most modern Hydraulic presses & automatic controlled
            Firing in long Tunnel Kilns.
          </p>
          <p>Products exhibits:</p>
          <li className={`ml-[20px]`}>High Dimensional accuracy.</li>
          <li className={`ml-[20px]`}>High alkali resistance</li>
          <li className={`ml-[20px]`}>Good creep resistance</li>
          <li className={`ml-[20px]`}>Energy loss minimum</li>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GlassPlant;
