import { Link, useNavigate } from "react-router-dom";

import styles from "../pages/HomePage.module.css";

import Navbar from "../components/Navbar";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
import EnquiryForm from "../components/EnquiryForm";
import NoticeSlider from "../components/NoticeSlider";

import ironPlant from "../assests/images/iron-industry.webp";
import powerPlant from "../assests/images/power-plant.webp";
import copperPlant from "../assests/images/copper-plant.webp";
import cementPlant from "../assests/images/cement-plant.webp";
import glassPlant from "../assests/images/glass-plant.webp";
import aluminiumPlant from "../assests/images/aluminium-plant.webp";

import basicRefactory from "../assests/UpdatePhoto/basic-refractories-250x250.webp";
import fireclayRefactory from "../assests/UpdatePhoto/aluminaProduct.webp";
import resistanceRefactory from "../assests/UpdatePhoto/acid-proof-bricks-500x500.webp";
import monolithicRefactory from "../assests/UpdatePhoto/monolithics-500x500.webp";
import rammingRefactory from "../assests/UpdatePhoto/gunning-mass-500x500.webp";
import slideRefactory from "../assests/UpdatePhoto/flowcontrol-500x500.webp";
import tundishRefactory from "../assests/UpdatePhoto/tundish.webp";
import specialRefactory from "../assests/UpdatePhoto/special-refractories-bricks-1000x1000.webp";
import GallerySlider from "../components/GallerySlider";
import Fadeimg from "./FadeOnimg/Fadeimg";
import RenderText from "../components/RenderTextAnimation/RenderText";

function HomePage() {
  const navigate = useNavigate()



  return (
    <>

      <Navbar />
      <Carousel />

      <div className={styles.home}>
        <div className={styles.container}>
          <h2 style={{ textAlign: "center" }}>
            <span className={` w-auto h-auto text-2xl font-bold py-[5px] px-[10px] text-[#4c4c4c]`}>Welcome to Champion Ceramics</span>
          </h2>
          <div className={styles.lineHeight}>
            <RenderText>
              <p>
                <b>Champion Ceramics Pvt. Ltd.</b>, was established in the year
                1987. Champion Ceramics Pvt. Ltd., is situated at a very strategic
                location of Central India namely CHAMPA (Chattisgarh) is located
                on the Howrah - Mumbai (via Nagpur) main line of South East
                Central Railway.
              </p>
            </RenderText>
            <RenderText>

              <p>
                From a humble beginning this unit has now grown into a
                large-modern works manufacturing:- Basic Refractory Products,
                Fireclay & High Alumina Bricks, Acid & Heat Resistance Bricks,
                Monolithics & Castables, Ramming, Gunning & Spray masses, Slide
                Gate / Flow Control Refractories, Tundish Refractories & Special
                Refractories for different categories of industries. The products
                of the company enjoy a high reputation for quality and durability
                and are in great demand all over the country.
              </p>
            </RenderText>
            <RenderText>

              <p>
                We are proud of our heritage and long association with refractory
                consuming industries.
              </p>
            </RenderText>
            <div className={styles.btnRead}>
              <Link to="/aboutus">
                <button className={styles.readMoreBtn}>Read More</button>
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.background}>
          <div className={styles.infraStructureContent}>
            <h2 style={{ textAlign: "center" }}>
              <span className={`w-auto h-auto text-xl font-bold px-[10px] py-[3px]`}>
                Industries we work for
              </span>
            </h2>
            <RenderText>

              <p>
                Champion Ceramics Pvt.Ltd., has been a leading manufacturer and
                supplier of refractory products. We Cater our products to
                following type of industries :-
              </p>
            </RenderText>

            <div className={styles.infraImg}>
              <div onClick={() => navigate("/iron-and-steel-plant")} >
                <div className={styles.box3}>

                  <img src={ironPlant} alt="Iron & steel plant" />

                  <div className={` w-full h-auto px-[10px] text-xl  bottom-0`}>
                    <div className={`w-full h-full bg-black absolute top-0 opacity-40 left-0`}></div>
                    <h3 className={`absolute !font-serif w-full h-full flex items-center z-20 text-white justify-center top-0 left-0`}>Iron & steel plant</h3>
                  </div>
                </div>
              </div>
              <Link to="/power-plant">
                <div className={styles.box3}>

                  <img src={powerPlant} alt="Power Plant" />

                  <div className={`w-full h-auto px-[10px] text-xl  bottom-0`}>
                    <div className={`w-full h-full bg-black absolute top-0 opacity-40 left-0`}></div>
                    <h3 className={`absolute !font-serif sm:z-[15] w-full h-full flex items-center z-20 text-white justify-center top-0 left-0`}>Power Plant</h3>
                  </div>
                </div>
              </Link>
              <Link to="/copper-plant">
                <div className={styles.box3}>

                  <img src={copperPlant} alt="Copper Plant" />


                  <div className={`w-full h-auto px-[10px] text-xl  bottom-0`}>
                    <div className={`w-full h-full bg-black absolute top-0 opacity-40 left-0`}></div>
                    <h3 className={`absolute !font-serif sm:z-[15] w-full h-full flex items-center z-20   text-white justify-center top-0 left-0`}>Copper Plant</h3>
                  </div>
                </div>
              </Link>

              <Link to="/cement-plant">
                <div className={styles.box3}>
                  <img src={cementPlant} alt="Cement Plant" />

                  <div className={`w-full h-auto px-[10px] text-xl  bottom-0`}>
                    <div className={`w-full h-full bg-black absolute top-0 opacity-40 left-0`}></div>
                    <h3 className={`absolute !font-serif sm:z-[15] w-full h-full flex items-center z-20 text-white justify-center top-0 left-0`}>Cement Plant</h3>
                  </div>
                </div>
              </Link>

              <Link to="/glass-plant">
                <div className={styles.box3}>

                  <img src={glassPlant} alt="Glass Plant" />


                  <div className={`w-full h-auto px-[10px] text-xl  bottom-0`}>
                    <div className={`w-full h-full bg-black absolute top-0 opacity-40 left-0`}></div>
                    <h3 className={`absolute !font-serif sm:z-[15] w-full h-full flex items-center z-20 text-white justify-center top-0 left-0`}>Glass Plant</h3>
                  </div>
                </div>
              </Link>
              <Link to="/aluminium-plant">
                <div className={styles.box3}>
                  <img src={aluminiumPlant} alt="Aluminum Plant" />
                  <div className={`w-full h-auto px-[10px] text-xl  bottom-0`}>
                    <div className={`w-full h-full bg-black absolute top-0 opacity-40 left-0`}></div>
                    <h3 className={`absolute !font-serif sm:z-[15] w-full h-full flex items-center z-20 text-white justify-center top-0 left-0`}>Aluminum Plant</h3>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.infraStructureContent}>
          <h2 style={{ textAlign: "center" }}>
            <span className={`font-bold text-xl px-[10px] py-[4px]`}>Our Product</span>
          </h2>
          {/* <p>We have widest Product Basket which includes following items :-</p> */}
          <div className={styles.productContent}>
            <div className={styles.infraImg}>
              <div className={`${styles.box3} group !relative rounded-[40px]`}>
                {/* <Fadeimg> */}
                <img src={basicRefactory} className={`border border-[#ccc]`} alt="ProductImg" />
                {/* </Fadeimg> */}
                <div className={`slow-load w-full rounded-md flex !bg-transparent h-full z-20 sm:z-[18] font-[600] items-center justify-center px-[10px] py-[5px] text-xl absolute bottom-0      `}>
                  <div className={` absolute top-0 bg-black opacity-40 group-hover:bg-red-500 cursor-pointer left-0 w-full h-full`}></div>
                  <h3 className={`text-animation z-20 cursor-pointer text-white`}>
                    Basic Refactory Product
                  </h3>
                </div>
              </div>
              <div className={`${styles.box3} group !relative rounded-[40px]`}>

                <img src={fireclayRefactory} className={`border border-[#ccc]`} alt="ProductImg" />

                <div className={`slow-load w-full rounded-md flex !bg-transparent h-full z-20 sm:z-[18]  font-[600] items-center justify-center px-[10px] py-[5px] text-xl absolute bottom-0       `}>
                  <div className={` absolute top-0 bg-black opacity-40 group-hover:bg-red-500 cursor-pointer left-0 w-full h-full`}></div>
                  <h3 className={`text-animation z-20 cursor-pointer text-white`}>Fireclay & High Alumna</h3>
                </div>
              </div>
              <div className={`${styles.box3}  group !relative rounded-[40px]`}>

                <img src={resistanceRefactory} className={`border border-[#ccc]`} alt="ProductImg" />

                <div className={`slow-load w-full rounded-md flex !bg-transparent h-full z-20 sm:z-[18]  font-[600] items-center justify-center px-[10px] py-[5px] text-xl absolute bottom-0   `}>
                  <div className={` absolute top-0 bg-black opacity-40 group-hover:bg-red-500 cursor-pointer left-0 w-full h-full`}></div>
                  <h3 className={`text-animation z-20 cursor-pointer text-white`}>Acid & Heat Resistance</h3>
                </div>

              </div>
              <div className={`${styles.box3}  group !relative rounded-[40px]`}>

                <img src={monolithicRefactory} className={`border border-[#ccc]`} alt="ProductImg" />

                <div className={`slow-load w-full rounded-md flex !bg-transparent h-full z-20 sm:z-[18]  font-[600] items-center justify-center px-[10px] py-[5px] text-xl absolute bottom-0   `}>
                  <div className={` absolute top-0 bg-black opacity-40 group-hover:bg-red-500 cursor-pointer left-0 w-full h-full`}></div>
                  <h3 className={`text-animation z-20 cursor-pointer text-white`}>Monolithic & Castable</h3>
                </div>

              </div>

              <div className={`${styles.box3}  group !relative rounded-[40px]`}>

                <img src={rammingRefactory} className={`border border-[#ccc]`} alt="ProductImg" />

                <div className={`slow-load w-full rounded-md flex !bg-transparent h-full z-20 sm:z-[18]  font-[600] items-center justify-center px-[10px] py-[5px] text-xl absolute bottom-0   `}>
                  <div className={` absolute top-0 bg-black opacity-40 group-hover:bg-red-500 cursor-pointer left-0 w-full h-full`}></div>
                  <h3 className={`text-animation z-20 cursor-pointer text-white`}>Ramming/ Gunning Spray Masses</h3>
                </div>

              </div>

              <div className={`${styles.box3}  group !relative rounded-[40px]`}>

                <img src={slideRefactory} className={`border border-[#ccc]`} alt="ProductImg" />

                <div className={`slow-load w-full rounded-md flex !bg-transparent h-full z-20 sm:z-[18]  font-[600] items-center justify-center px-[10px] py-[5px] text-xl absolute bottom-0   `}>
                  <div className={` absolute top-0 bg-black opacity-40 group-hover:bg-red-500 cursor-pointer left-0 w-full h-full`}></div>
                  <h3 className={`text-animation z-20 cursor-pointer text-white`}>Slide Gate/ Flow Control Refractories</h3>
                </div>

              </div>

              <div className={`${styles.box3}  group !relative rounded-[40px]`}>

                <img src={tundishRefactory} className={`border border-[#ccc]`} alt="ProductImg" />

                <div className={`slow-load w-full rounded-md flex !bg-transparent h-full z-20 sm:z-[18]  font-[600] items-center justify-center px-[10px] py-[5px] text-xl absolute bottom-0   `}>
                  <div className={` absolute top-0 bg-black opacity-40 group-hover:bg-red-500 cursor-pointer left-0 w-full h-full`}></div>
                  <h3 className={`text-animation z-20 cursor-pointer text-white`}>Tundish Refractories</h3>
                </div>

              </div>


              <div className={`${styles.box3}  group !relative rounded-[40px]`}>

                <img src={specialRefactory} className={`border border-[#ccc]`} alt="ProductImg" />

                <div className={`slow-load w-full rounded-md flex !bg-transparent h-full z-20 sm:z-[18]  font-[600] items-center justify-center px-[10px] py-[5px] text-xl absolute bottom-0   `}>
                  <div className={` absolute top-0 bg-black opacity-40 group-hover:bg-red-500 cursor-pointer left-0 w-full h-full`}></div>
                  <h3 className={`text-animation z-20 cursor-pointer text-white`}>Special Refractories</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.enquiryNotice}>
        <NoticeSlider />
        <EnquiryForm />
      </div>
      <GallerySlider />
      <Footer />
    </>
  );
}

export default HomePage;
