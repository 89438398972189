import { useEffect, useRef, useState } from "react";
import styles from "./GallerySlider.module.css";

import image1 from "../assests/images/gallery1.webp";
import image2 from "../assests/images/gallery2.webp";
import image3 from "../assests/images/gallery3.webp";
import image4 from "../assests/images/gallery4.webp";
import image5 from "../assests/images/gallery5.webp";
import image6 from "../assests/images/gallery6.webp";
import image7 from "../assests/images/gallery7.webp";
import image8 from "../assests/images/gallery8.webp";
import image9 from "../assests/images/gallery9.webp";
import image10 from "../assests/images/gallery10.webp";
import image11 from "../assests/images/gallery11.webp";
import image12 from "../assests/images/gallery12.webp";
import { Link } from "react-router-dom";
import Slider from "react-slick";
const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,

  // Add more image URLs here
];

function GallerySlider() {
  const [Mobileimages, setMobileimages] = useState([])
  const settings = {
    // Show dots for navigation
    infinite: true,           // Infinite loop
    speed: 500,               // Transition speed in milliseconds
    slidesToShow: 3,          // Number of slides to show at a time
    slidesToScroll: 1,        // Number of slides to scroll at a time
    autoplay: true,           // Enable auto-sliding
    autoplaySpeed: 1500,      // Delay between slides in milliseconds
    arrows: false,          // Hide arrows

  };

  const MobileimagesSetting = {
    infinite: true,           // Infinite loop
    speed: 500,               // Transition speed in milliseconds
    slidesToShow: 1,          // Number of slides to show at a time
    slidesToScroll: 1,        // Number of slides to scroll at a time
    autoplay: true,           // Enable auto-sliding
    autoplaySpeed: 1500,      // Delay between slides in milliseconds
    arrows: false,
  }
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const slider = useRef();

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 4000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <h1 className={`w-full flex items-center justify-center h-auto`}>
        <span className={`text-xl font-bold text-[#4c4c4c] border-b px-[5px] border-b-[#4c4c4c]`}>Gallery</span>
      </h1>
      <div className={styles.slider}>
        <button className={`${styles.prev} sm:!z-[19]`} onClick={() => slider.current && slider.current.slickPrev()}>
          &#10094;
        </button>
        <button className={`${styles.next} sm:!z-[19]`} onClick={() => slider.current.slickNext()}>
          &#10095;
        </button>
        <div className={styles.slideContainer}>
          <Slider ref={slider} {...settings} className={`w-full sm:hidden my-[40px] flex px-[30px] sm:h-[200px] h-[300px]`}>
            {images.map((img) => {
              return (
                <>
                  <div className={`w-full overflow-hidden px-[20px] sm:px-[10px] h-[300px]`}>
                    <img src={img} className={`w-full hover:scale-110 transition-all sm:bg-center sm:bg-cover hover:transition-all sm:h-[200px] h-full`} />
                  </div>
                </>
              )
            })}

          </Slider>
          <Slider ref={slider} {...MobileimagesSetting} className={`w-full hidden my-[40px] sm:flex px-[30px] sm:h-[200px] h-[300px]`}>
            {images.map((img) => {
              return (
                <>
                  <div className={`w-full overflow-hidden px-[20px] sm:px-[10px] h-[300px]`}>
                    <img src={img} className={`w-full hover:scale-110 transition-all sm:bg-center sm:bg-cover hover:transition-all sm:h-[200px] h-full`} />
                  </div>
                </>
              )
            })}

          </Slider>
        </div>
      </div >
    </>
  );
}

export default GallerySlider;
