import styles from "./AboutusPage.module.css";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import AfterCarousel from "../components/AfterCarousel";

import director1 from "../assests/images/niket-agrawal-665476cde75a3.webp";
import director2 from "../assests/images/prateek-agrawal-665476cf980c4.webp";
import director3 from "../assests/images/praveer-agrawal-665476d066e64.webp";
import director4 from "../assests/images/sajjan-prashad-agrawal-665476d3c00bf.webp";

import carouselImg from "../assests/AboutUsHeader.jpg";

import professional from "../assests/images/professionals-665476d17567c.webp";
import automation from "../assests/images/automation-665476ceef772.webp";
import upgraded1 from "../assests/images/upgraded-quality-process-1-665476d4ae160.webp";
import upgraded2 from "../assests/images/upgraded-quality-process-2-665476d814c39.webp";
import upgraded3 from "../assests/images/upgraded-quality-process-3-665476d8a6518.webp";
import WhyImg1 from "../assests/AboutUsImage.jpg"
import WhyImg2 from "../assests/AboutImage2.jpg"
import Whyimg3 from "../assests/AboutUsImage3.jpg"
import WhyImg4 from "../assests/AboutUsImage4.jpg"
import WhyImg5 from "../assests/AboutUsImage5.jpg"
import Fadeimg from "./FadeOnimg/Fadeimg";
import RenderText from "../components/RenderTextAnimation/RenderText";

function AboutusPage() {
  const whywearediffrent = [
    WhyImg1,
    WhyImg2,
    Whyimg3,
    WhyImg4,
    WhyImg5
  ]

  return (
    <>
      <Navbar />
      <Banner heading="About Us" img={carouselImg} />
      <AfterCarousel />
      <div className={styles.about}>
        <div className={styles.container}>
          <h2 style={{ textAlign: "center" }}>
            <span className={`sm:text-[25px] sm:!px-[7px]`}>About Us</span>
          </h2>
          <div>
            <RenderText>
              <p className={`!text-black`}>
                <b>Champion Ceramics Pvt. Ltd.</b>, was established in the year
                1987. Champion Ceramics Pvt. Ltd., is situated at a very strategic
                location of Central India namely CHAMPA (Chattisgarh) is located
                on the Howrah - Mumbai (via Nagpur) main line of South East
                Central Railway.
              </p>
            </RenderText>
            <RenderText>
              <p className={`!text-black`}>
                From a humble beginning this unit has now grown into a
                large-modern works manufacturing:- Basic Refractory Products,
                Fireclay & High Alumina Bricks, Acid & Heat Resistance Bricks,
                Monolithics & Castables, Ramming, Gunning & Spray masses, Slide
                Gate / Flow Control Refractories, Tundish Refractories & Special
                Refractories for different categories of industries. The products
                of the company enjoy a high reputation for quality and durability
                and are in great demand all over the country.
              </p>
            </RenderText>
            <RenderText>
              <p className={`!text-black`}>
                We are proud of our heritage and long association with refractory
                consuming industries.
              </p>
            </RenderText>
          </div>
          <div>
            <div className={styles.container}>
              <h2>
                <span style={{ paddingLeft: '0px', fontSize: '1.5rem' }} className={`sm:!text-[20px] sm:!pr-[5px]`}>Why we are different</span>
              </h2>
              <div>
                <RenderText>
                  <p>
                    Champion Ceramics Pvt. Ltd., is committed to meet the
                    stringent quantitative and qualitative requirements of the
                    customers. All our products confirm as per IS and
                    International Specification, which are supported through a
                    comprehensive pre and post service plan depending on the
                    customers need.
                  </p>
                </RenderText>
                <RenderText>
                  <p>
                    We have in hand an ambitious expansion programme. We also have
                    in hand projects manufacturing Silica Refractories
                  </p>
                </RenderText>
              </div>

              <div className={`w-full min-h-[200px] flex items-center sm:flex-wrap sm:h-[100px] sm:justify-center sm:gap-2 justify-between my-[10px] mb-[30px]`}>
                {
                  whywearediffrent.map((item) => {
                    return (
                      <>
                        <Fadeimg>
                          <img className={`w-[200px] sm:h-[100px] sm:w-[100px] border-2 border-[#ccc] rounded-md shadow-lg h-[200px] bg-center bg-cover overflow-hidden`} src={item} />

                        </Fadeimg>
                      </>
                    )
                  })
                }
              </div>
            </div>
            <div className={styles.container}>
              <h2>
                <span style={{ paddingLeft: '0px', fontSize: '1.5rem', paddingRight: '15px' }} className={`sm:!text-[20px]`}>Vision</span>
              </h2>
              <div>
                <RenderText>
                  <p>
                    At Chmapion Ceramcs Pvt. Ltd, we don't just manufacture
                    refractory solutions, we craft them with an unwavering focus
                    on quality. Our vision is to be the country's premier
                    refractory manufacturer and solution provider to the iron,
                    steel,& Different industries, and we are relentlessly
                    committed to achieving it.
                  </p>
                </RenderText>
              </div>
            </div>
            <div className={styles.container}>
              <h2>
                <span style={{ paddingLeft: '0px', fontSize: '1.5rem', paddingRight: '15px' }} className={`sm:!text-[20px]`}>Mission</span>
              </h2>
              <div>
                <RenderText>
                  <p>
                    Our commitment is to deliver superior quality refractory
                    solutions that go above and beyond the expectations of our
                    valued stakeholders. We relentlessly pursue innovation and
                    excellence to ensure that we remain the leading provider of
                    reliable refractory manufacturing solutions to the iron,
                    steel,& Different industries
                  </p>
                </RenderText>
              </div>
            </div>
            <div className={`${styles.alignDiv} h-auto flex items-start justify-start  `}>
              <div style={{ marginTop: '0px', width: '78%', marginLeft: '0px', justifyContent: 'start' }} className={`${styles.container} sm:!w-full`}>
                <h2>
                  <span style={{ paddingLeft: '0px', fontSize: '1.5rem', paddingRight: '15px' }} className={`sm:!text-[20px]`}>Team of 500+ Professionals:</span>
                </h2>
                <RenderText>
                  <p style={{ fontWeight: 700 }}>
                    "Experience, expertise, and excellence at your service."
                  </p>
                  <p>
                    We are a team of more than 500 professionals, dedicated to
                    providing our customers with the best possible products and
                    services. Our team includes highly skilled engineers,
                    technicians, and support staff who bring a wealth of
                    experience and expertise. We are committed to ongoing training
                    and development, ensuring our team members stay up-to-date
                    with the latest technologies.
                  </p>
                </RenderText>
              </div>
              <div className={styles.imageDiv}>
                <Fadeimg>
                  <img className={`border  border-[#ccc] rounded-md shadow-lg`} src={professional} alt="About" />

                </Fadeimg>
              </div>
            </div>

            <div className={styles.alignDiv}>
              <div style={{ marginTop: '0px', width: '77%', marginLeft: '0px', justifyContent: 'start' }} className={`${styles.container} sm:!w-full`}>
                <h2>
                  <span style={{ paddingLeft: '0px', fontSize: '1.5rem', paddingRight: '15px' }} className={`sm:!text-[20px]`}>Automation & IT Enabled:</span>
                </h2>
                <RenderText>
                  <p style={{ fontWeight: 700 }}>
                    "Bringing innovation to life through automation and IT."
                  </p>
                  <p>
                    We are constantly exploring new ways to innovate and improve
                    our business practices. One of the ways we do this is through
                    automation and IT-enabled solutions that streamline our
                    processes and ensure consistent quality across all our
                    products. By combining the latest technologies with our
                    skilled workforce, we deliver reliable, efficient, and
                    high-quality refractory solutions to businesses of all sizes.
                  </p>
                </RenderText>
              </div>
              <div className={`${styles.imageDiv} relative whiteshades`}>
                <Fadeimg>
                  <img className={`rounded-md  `} src={automation} alt="About" />
                </Fadeimg>
              </div>
            </div>

            <div className={styles.alignDivColumn}>
              <div className={styles.container}>
                <h2>
                  <span style={{ paddingLeft: '0px', fontSize: '1.5rem', paddingRight: '15px' }}>Upgraded Quality Process:</span>
                </h2>
                <RenderText>
                  <p style={{ fontWeight: 700 }}>
                    "Setting the standard for quality in the refractory industry."
                  </p>
                  <p>
                    Champion is committed to delivering the highest quality
                    refractory products and services to our customers. Our quality
                    control procedures cover every aspect of our business, from
                    raw material sourcing and processing to product testing and
                    delivery, ensuring that every product that leaves our facility
                    meets our rigorous quality standards.
                  </p>
                </RenderText>
              </div>
              <div className={`${styles.imageDiv} sm:flex-col`}>
                <Fadeimg>
                  <img src={upgraded1} className={``} alt="About" />

                </Fadeimg>
                <Fadeimg>

                  <img src={upgraded2} className={` `} alt="About" />

                </Fadeimg>
                <Fadeimg>
                  <img src={upgraded3} className={` `} alt="About" />

                </Fadeimg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className={styles.container1}>
          <h2 className={`text-xl font-[Arial, Helvetica, sans-serif]`}>
            <span className={`sm:!border-b-2 sm:!px-[5px] sm:!border-b-black`}>Our Directors</span>
          </h2>
          <div className={styles.directorContainer}>
            <div className={`${styles.directors}`}>
              <Fadeimg>
                <img src={director1} alt="NIKET AGRAWAL" />

              </Fadeimg>
              <h3 className={`font-serif font-[500] border bg-[#ccc]`}>NIKET AGRAWAL</h3>
            </div>
            <div className={styles.directors}>
              <Fadeimg>
                <img src={director2} alt="PRATEEK AGRAWAL" />

              </Fadeimg>
              <h3 className={`font-serif font-[500] border bg-[#ccc]`}>PRATEEK AGRAWAL</h3>
            </div>
            <div className={styles.directors}>
              <Fadeimg>
                <img src={director3} alt="PRAVEER AGRAWAL" />

              </Fadeimg>
              <h3 className={`font-serif font-[500] border bg-[#ccc]`}>PRAVEER AGRAWAL</h3>
            </div>
            <div className={styles.directors}>
              <Fadeimg>
                <img src={director4} alt="SAJJAN PRASHAD AGRAWAL" />

              </Fadeimg>
              <h3 className={`font-serif font-[500] border bg-[#ccc]`}>SAJJAN PRASHAD AGRAWAL</h3>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutusPage;
