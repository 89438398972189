import React, { useEffect, useState } from "react";
import styles from "./ProductionSlider.module.css";
import managementTeam from "../assests/images/management-66547ead17228.webp"
import Fadeimg from "../pages/FadeOnimg/Fadeimg";

function ProductionSlider() {







  return (
    <>
      <div className={styles.slider}>
        <div className={styles.slideContainer}>
          <h1 className={`text-xl text-[#4c4c4c] border-b-2 border-b-[#4c4c4c]  sm:text-[17px] sm:px-[10px]  py-[10px] font-bold `}>
            Management Team
          </h1>
          <div className={styles.slide}>
            <Fadeimg>
              <img
                className={styles.image}
                src={managementTeam}
                alt={`Slide  `}
              />
            </Fadeimg>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductionSlider;
