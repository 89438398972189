import styles from "./NavHead.module.css";

import instagram from "../assests/imgs/instagram.png";
import facebook from "../assests/imgs/facebook.png";
import twitter from "../assests/imgs/twitter.png";
import callIcon from "../assests/imgs/callIcon.png";

import navlogo from "../assests/images/navlogo.png";
import navlogo2 from "../assests/images/navlogo2.svg";

import logo from "../assests/imgs/logo1.png";
import { Link } from "react-router-dom";

function NavHead() {
  return (
    <div className={`${styles.container} sm:!fixed sm:z-[20]`}>

      <div className={`w-auto gap-[10px] h-full flex items-center justify-center flex-row`}>
        <div className={`${styles.logo} sm:!h-[50px]`}>
          <img src={`${logo} `} className={`sm:!h-[50px]`} alt="" />
          {/* <p className={`w-auto text-xl font-[800]`}>CHAMPION</p> */}
        </div>

        <div className={` text-md font-[600]`}>
          <h2 className={`font-[750] text-xl sm:text-[15px]`}>CHAMPION CERAMICS PVT. LTD.</h2>
          <h5 className={`sm:text-[13px]`}>(AN ISO 9001:2008 CERTIFIED COMPANY)</h5>
          {/* <h3>WIDE RANGE OF REFRACTORIES</h3> */}
        </div>
      </div>

      <div className={`${styles.navlogo} flex items-center justify-center`}>
        <img src={navlogo} alt="" />
        <img src={navlogo2} alt="" style={{ backgroundColor: "white" }} />
      </div>
      {/* <p className={styles.mail}>
        <span className={styles.fontW}>✉ championceramics@gmail.com</span>

        <img src={callIcon} alt="" className={styles.callIcon} />

        <span className={styles.fontW}>+91-9999999999</span>
      </p>
      <p className={styles.logo}>
        <span>
          <img src={instagram} alt="" />
        </span>
        <span>
          <img src={facebook} alt="" />
        </span>
        <span>
          <img src={twitter} alt="" />
        </span>
      </p> */}
    </div>
  );
}

export default NavHead;
