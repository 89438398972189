import { useState } from "react";
import axios from "axios";
import styles from "./ImageUpload.module.css";

function ImageUpload() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [uploadMessage, setUploadMessage] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedImage) return;

    const formData = new FormData();
    formData.append("file", selectedImage);

    try {
      const response = await axios.post(
        "http://localhost:5000/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUploadMessage("Image uploaded successfully");
      console.log("File uploaded successfully", response.data);
      // Reset input and preview after successful upload
      setSelectedImage(null);
      setPreviewImage(null);
    } catch (error) {
      setUploadMessage("Error uploading the file");
      console.error("Error uploading the file", error);
    }
  };

  return (
    <div className={styles.imageUpload}>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className={styles.fileInput}
        key={selectedImage ? selectedImage.name : ""}
      />
      {previewImage && (
        <div className={styles.imagePreview}>
          <img src={previewImage} alt="Uploaded" className={styles.image} />
        </div>
      )}
      <button onClick={handleUpload} className={styles.uploadBtn}>
        Upload Image
      </button>
      {uploadMessage && <p className={styles.uploadMessage}>{uploadMessage}</p>}
    </div>
  );
}

export default ImageUpload;
