import React from 'react'

const ProductItemBanner = ({ img, className, heading = "heading" }) => {
    return (
        <div className={` bg-contain   overflow-hidden sm:h-[200px] relative bg-center w-full h-[450px] top-[88px] flex items-center justify-center`}>

            <div style={{ backgroundImage: `url(${img})` }} className={`bg-cover bg-center w-full h-full absolute top-0 left-0 `}></div>
            <div style={{ backgroundImage: `url(${img})` }} className={` bg-contain bg-no-repeat border-none object-fill absolute z-[19]  top-0 border bg-center w-[40%] h-full`}></div>
            <h1 className={`w-full h-auto text-5xl sm:text-xl font-[400] text-[#4c4c4c] absolute z-[19] top-[40%]  flex items-center justify-center`}>
                <span className={`w-auto border-2 border-[#4c4c4c] sm:text-xl h-auto text-5xl bg-white p-2 rounded-md font-[400] text-[#4c4c4c]`}>
                    {heading}
                </span>
            </h1>
        </div>
    )
}

export default ProductItemBanner