import Footer from "./Footer";
import Navbar from "./Navbar";

import styles from "./IronSteelPlant.module.css";

import eaf from "../assests/images/eaf.jpg";
import eaf2 from "../assests/images/eaf2.jpg";
import eaf3 from "../assests/images/eaf3.jpg";

import bof1 from "../assests/images/bof1.jpg";
import bof2 from "../assests/images/bof2.jpg";

import steelladle1 from "../assests/images/steelladle1.jpg";
import slideplates1 from "../assests/images/slideplates1.jpg";
import tundish1 from "../assests/images/tundish1.jpg";
import limekiln1 from "../assests/images/limekiln1.jpg";
import spongironkilns from "../assests/images/spongironkilns.jpg";
import cokeoven from "../assests/images/cokeoven.jpg";
import blastfurnace1 from "../assests/images/blastfurnace1.jpg";
import torpedoledle from "../assests/images/torpedoledle.jpg";
import torpedoledle2 from "../assests/images/torpedoledle2.jpg";
import transferladle1 from "../assests/images/transferladle1.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function IronSteelPlant() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scroll(0, 0)
  }, [pathname])

  return (
    <>
      <Navbar />
      <div className={`${styles.industries} sm:!mt-[90px]`}>
        <div className={`${styles.ironSteel} `}>
          <h1 className={`font-bold text-xl`}>IRON AND STEEL</h1>

          <div className={`${styles.box} sm:!flex-col sm:items-center`}>
            <div className={`${styles.imgBox} w-[400px] sm:w-[240px] sm:!mb-[20px]`}>
              <img src={eaf} alt="Electric Arc Furnace" className={`w-full h-full`} />
            </div>
            <div className={`${styles.content} sm:!p-0  `}>
              <h2 className={`text-[20px] font-[500]`}>Electric Arc Furnace (EAF)</h2>
              <p>
                Several technological developments have taken place in EAF steel
                making in order to increase productivity and to reduce cost per
                ton of steel. Variours technological develpments include :
              </p>
              <p>1. UHP/long arc operation</p>
              <p>2. Water colled wall and roof panel</p>
              <p>3. Foamy slag practice</p>
              <p>4. Oxy-fuel burner</p>
              <p>5. Inert gas purging</p>
              <p>6. Eccentric bottom tapping</p>
              <p>7. DC EAF</p>
            </div>
          </div>

          <div className={styles.secondBox}>
            <p>
              CCPL has complete range of products such as burnt magnesia bricks
              for backup application, MgO-C bricks for all areas, dry ramming
              mass and basic gunning material. CCPL provides High alumina
              castable and brick for EAF roof as per requirment of customers.
            </p>
            <div className={`${styles.imageDiv} sm:flex-col sm:flex sm:items-center sm:gap-5 sm:justify-center`}>
              <img className={`sm:!w-[220px] sm:!h-auto`} src={eaf2} alt="eaf2" />
              <img className={`sm:!w-[220px] sm:!h-auto`} src={eaf3} alt="eaf3" />
            </div>
          </div>

          <div className={styles.bof}>
            <div className={styles.bofContainer}>
              <h2 className={`text-[20px] font-[500]`}>Basic Oxygen Furnace (BOF)</h2>
              <p>
                All over the world, BOF has emerged as prime steel making unit
                followed by EAF. CCPL has a wide range of Magnesia carbon
                refractories for BOF, EAF and Ladle are produced.
              </p>
              <p>
                These bricks are manufactured with high purity raw materials
                (Fused magnesia, Graphite and antioxidants), using new
                generation mixers and presses and tempering in a modern kiln.
                CCPL has a wide range of Magnesia carbon bricks for different
                areas of converter. The quality of MgO-C bricks for all the
                areas are continuously upgraded by CCPL.
              </p>
              <p>
                Various repair materials are also provided by CCPL for extending
                the performance of BOF refractories. CPL also provides various
                refractories (Burnt magnesia and magnesia chrome bricks) for BOF
                back up lining.
              </p>
            </div>

            <div>
              <div className={`${styles.bofImage} sm:flex-col sm:flex sm:items-center sm:gap-5 sm:justify-center`}>
                <img className={`sm:!w-[220px] sm:!h-auto`} src={bof1} alt="Electric Arc Furnace" />
                <img className={`sm:!w-[220px] sm:!h-auto`} src={steelladle1} alt="Steel Ladle" />
              </div>
              <div className={styles.bofContent}>
                <h2 className={`text-[20px] font-[500]`}>Steel Ladle</h2>
                <p>
                  During last two decades, the demand for various grades of
                  steel with stringent specifications has increased
                  considerably. Initially Steel ladle was used only to transport
                  steel from steel making units to casting bay. But now the
                  steel ladle lining must withstand increasingly severe service
                  conditions associated with secondary steel making in ladle.
                  Secondary steel making is associated with longer holding time,
                  higher liquid temperature and arc/chemical heating. Stirring
                  with inert gases and degassing procedure, alloying and use of
                  synthetic slag will also accelerate the wear. With the
                  introduction of secondary refining and continuous casting,
                  superior quality refractories are required for lining steel
                  ladle.
                </p>
                <p>
                  CCPL has wide range of refractories for steel ladle
                  application. These refractories are pitch/resin-bonded
                  dolomite, MgO-C, Alumina magnesia carbon, burnt alumina
                  spinel, high alumina and fired magnesia chrome brick.
                </p>
                <p>
                  Precast prefired well block/seating block, castable/ramming
                  masses for fixing well block/seating block, ladle bottom
                  periphery and top tightening application are supplied by CCPL
                </p>

                <p>
                  High alumina and basic bricks are also offered by CCPL for
                  ladle back up application.
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className={styles.bofContent}>
              <h2 className={`text-[20px] font-[500]`}>Slide Plates & Accessories</h2>
              <p>
                CCPL manufactures a wide range of slide plates (magnesia,
                alumina-carbon, alumina-zirconia carbon, Magnesia-spinel etc),
                ladle nozzles ( alumina based, magnesia – graphite base),
                collector nozzles, porous plugs (random & directional type),
                zircon nozzles, seating blocks, well blocks, sleeves, slide
                plate fixing masses etc.
              </p>
              <p>
                These products are manufactured from highest purity synthetic
                raw material & processed by using most modern manufacturing
                equipments. CCPL products are consistently giving multiple heats
                & serving the needs of steel plant ladles, tundish etc.
              </p>
            </div>
            <div>
              <div className={`${styles.bofImage} sm:flex sm:!flex-col sm:!items-center sm:!justify-center `}>
                <img src={slideplates1} className={`sm:w-[220px] sm:h-[220px]`} alt="Slide Plates & Accessories" />
                <img src={tundish1} alt="Tundish" className={`sm:w-[220px] sm:h-[220px]`} />
              </div>
            </div>
          </div>

          <div>
            <div className={styles.bofContent}>
              <h2 className={`text-[20px] font-[500]`}>Tundish</h2>
              <p>
                The production of steel through continuous casting route has
                increased considerably as it provides a number of advantages
                over conventional ingot casting. Currently, the world steel
                production through continuous casting route is about 95%.
              </p>
              <p>
                In continuous casting, tundish is the last refractory lined unit
                through which molten steel passes into the mould. In the early
                stage, tundish was used as steel distributor and retainer. Later
                on, it has been made larger in capacity to stabilize the
                operation of high speed casting and improving the quality of
                steel with an emphasis on the removal of inclusions. Various
                refractories associated with tundish are:
              </p>
              <p>(a) Tundish lining materials (Permanent and working lining)</p>
              <p>(b) Dams & Weirs</p>
              <p>(c) Impact pad</p>
              <p>
                (d) Flow control system (Monoblock stopper or tundish slide
                gate)
              </p>
              <p>
                (e) Pouring stream protection between tundish and mould (Shroud
                or SEN)
              </p>
              <p>(f) Tundish nozzle</p>
              <p>(g) Seating block</p>

              <p>
                CCPL manufactures & supplies low cement castable ( Castable
                LC-70, Castable LC-70 LI) for permanent lining application,
                basic spraying mass along with application equipment for working
                lining. A specific quality basic spraying mass is selected
                depending upon the requirement of sequence heats
              </p>
              <p>
                CCPL provides precast prefired dams, weire & impact pad by using
                superior quality low CEMENT CASTABLES and slide plates for flow
                control of steel
              </p>
              <p>
                CCPL also provides all black refractories (Mono block stopper,
                shrouds & SEN) to meet customers specific requirements.
              </p>
            </div>
          </div>

          <div>
            <div className={styles.bofContent}>
              <h2 className={`text-[20px] font-[500]`}>Lime Kiln</h2>
              <p>
                CCPL offers a comprehensive range of Fire Clay / High alumina,
                Magnesia, Magnesia – Zirconia, Magnesia – alumina, insulation &
                all range of castables & ramming masses for vertical shaft lime
                kiln. This product exhibits good hydration resistance, excellent
                resistance towards corrosive lime, high abrasion resistance &
                good thermo mechanical properties.
              </p>
            </div>

            <div className={`${styles.bofImage} sm:flex sm:gap-3 sm:!flex-col sm:!items-center sm:!justify-center `}>
              <img className={`sm:w-[220px] sm:h-auto`} src={limekiln1} alt="limekiln1" />
              <img className={`sm:w-[220px] sm:h-auto`} src={spongironkilns} alt="spongironkilns" />
            </div>
          </div>

          <div>
            <div className={styles.bofContent}>
              <h2 className={`text-[20px] font-[500]`}>Spong Iron Kilns</h2>
              <p>
                CCPL provides world class refractories for sponge iron kilns
                which includes low cement castables. Conventional castables and
                conventional insulating Castable. Low accretion build up has
                been observed in our castable which has been achieved through
                proper design of the recipe.
              </p>
              <p>
                These products are preferred by many users’ as these are
                manufactured by using selective raw materials like calcined
                bauxite with low iron oxide & high BD, high purity Talochite
                with high BD and high purity calcium aluminate cement, the best
                of its kind available in the globe. CCPL also supplies
                insulating bricks & mortars, Firebricks / HA bricks & mortars
                and other materials like anchors, calcium silicate blocks etc..
              </p>
              <p>
                CCPL recommends SI series castables for 50 to 100 TPD kilns
                whereas non SI series are recommended in bigger capacity kilns
                (100 TPD to 500 TPD) CCPL products are performing
                satisfactorily in different capacity kilns. Apart from supply of
                materials, CCPL provides state of the art refractories solutions
                which includes design, installation and expert supervision
                services.
              </p>
            </div>
          </div>

          <div className={styles.ironCoke}>
            <h1 className={`text-[25px] font-[500]`}>IRON And COKE</h1>
            <div>
              <div className={styles.cokeOven}>
                <h2 className={`text-[20px] font-[500]`}>Coke Oven</h2>
              </div>
              <div className={styles.cokeOvenContainer}>
                <img src={cokeoven} alt="spongironkilns" className={`sm:w-[220px] sm:h-auto`} />

                <div className={styles.cokeOvenContent}>
                  <p>
                    Champion Ceramics (P) Ltd. (CCPL) has been producing and
                    supplying all type of Fire clay Refractory Bricks and shapes
                    and Checker Bricks for Coke Oven as well as Blast furnace
                    Stove.
                  </p>
                  <p>
                    CCPL bricks has been used in more than 30 Recovery type and
                    Non-recovery type coke oven batteries and are giving
                    consistent performance over the years.
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.bf}>
              <h2 className={`text-[20px] font-[500]`}>Blast Furnace</h2>
              <div className={styles.bfContainer}>
                <img src={blastfurnace1} className={`sm:w-[220px] sm:h-auto`} alt="spongironkilns" />

                <div className={styles.Content}>
                  <p>
                    CCPL produces a wide range of blast furnace & stove
                    refractories meeting all international designer's
                    specification (M/S Paul-wurth, Siemens VAI, Danieli Corus,
                    Mecon etc.)
                  </p>
                  <p>
                    The products are manufactured from high purity Talochite,
                    Andalusite, Brown Corundum, Synthetic mullite grain and
                    exhibits excellent thermo-mechanical properties. A complete
                    range of monolithics and castables are also produced for BR
                    applications.
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.bf}>
              <h2 className={`text-[20px] font-[500]`}>Torpedo Ladle</h2>
              <div className={styles.Container}>
                <img src={torpedoledle} className={`sm:w-[220px] sm:h-auto`} alt="spongironkilns" />
                <div className={styles.Content}>
                  <p>
                    Torpedo ladle is mainly used for transporting hot metal from
                    BF to steel melting shop. Sometimes pretreatment of hot
                    metal is also carried out in torpedo ladle.
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.bfContainer}>
              <img src={torpedoledle2} className={`sm:w-[220px] sm:!h-auto`} alt="torpedoledle2" />
              <div>
                <p>
                  Torpedo ladle is mainly used for transporting hot metal from
                  BF to steel melting shop. Sometimes pretreatment of hot metal
                  is also carried out in torpedo ladle.
                </p>
              </div>
            </div>

            <div className={styles.transferLadle}>
              <div>
                <h2 className={`text-[20px] font-[500]`}>Transfer Ladle</h2>
                <div className={styles.bfContainer}>
                  <img src={transferladle1} className={`sm:w-[220px] sm:!h-auto`} alt="transferladle1" />

                  <p>
                    CCPL has been regularly supplying burnt HA bricks (Bauxite
                    based & fused alumina based) and ASC bricks for transfer
                    ladle application. These bricks are giving consistent
                    performance over the years.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default IronSteelPlant;
