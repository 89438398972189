import Footer from "./Footer";
import Navbar from "./Navbar";
import Banner from "./Banner";

import styles from "./QualityMeasures.module.css";

import img from "../assests/images/whoweare-66547f27b30c0.webp";

import bricksBlock from "../assests/images/qualityMeasure.webp";
import testing1 from "../assests/images/testing1-66547eb5349d8.webp";
import testing2 from "../assests/images/testing2-66547eb597920.webp";
import testing3 from "../assests/images/testing3-66547ebc9e0a8.webp";
import testing4 from "../assests/images/testing4-66547ebd49e10.webp";
import testing5 from "../assests/images/testing5-66547ec4cd710.webp";
import testing6 from "../assests/images/testing6-66547ec5c5ea8.webp";
import testing7 from "../assests/images/testing7-66547ecd761fd.webp";
import testing8 from "../assests/images/testing8-66547eced2742.webp";
import testing9 from "../assests/images/testing9-66547ed664ddc.webp";
import testing10 from "../assests/images/testing10-66547ed7adaa8.webp";
import testing11 from "../assests/images/testing11-66547edfe69ef.webp";
import testing12 from "../assests/images/testing12-66547ee1154f0.webp";
import testing13 from "../assests/images/testing13-66547ee958d05.webp";
import testing14 from "../assests/images/testing14-66547eea8c025.webp";
import testing15 from "../assests/images/testing15-66547ef278ace.webp";
import testing16 from "../assests/images/testing16-66547ef37bf28.webp";
import testing17 from "../assests/images/testing17-66547efb70f7b.webp";
import testing18 from "../assests/images/testing18-66547efc9e026.webp";
import testing19 from "../assests/images/testing19-66547f03e5585.webp";
import testing20 from "../assests/images/testing20-66547f05b1346.webp";
import testing21 from "../assests/images/testing21-66547f0de6771.webp";
import testing22 from "../assests/images/testing22-66547f0eca14c.webp";
import testing23 from "../assests/images/testing23-66547f1774547.webp";
import testing24 from "../assests/images/testing24-66547f185f2cb.webp";
import testing25 from "../assests/images/testing25-66547f1f94b1d.webp";

function QualityMeasures() {
  return (
    <>
      <Navbar />
      <Banner heading="Quality Measures" img={bricksBlock} />
      <div className={styles.container}>
        <h3 className={`w-full flex items-center justify-center text-xl font-bold text-[#4c4c4c]  `}>Quality Measures</h3>

        <div className={styles.box}>
          <p>
            At Champion Ceramics, we test everything. Products are developed
            after all formal stages of design and development. To ensure
            reliability in products supplied to you, our factory laboratory
            diligently tests samples from all sent out material. It is equipped
            to test products as per the requirements of many international
            standards including the major requirements of:
          </p>
          <p>
            <b>EN 13813</b> Screed material and Floor Screeds
          </p>
          <p>
            <b>EN 13892</b> Methods of test for screed materials (Part 1 to 8)
          </p>
          <p>
            <b>EN 998-1</b> Rendering and Plastering mortar
          </p>
          <p>
            <b>EN 998-2</b> Masonry Mortar
          </p>
          <p>
            <b>EN 12004-2:2017</b> Adhesives for Ceramic Tiles - Part 2: Test
            Methods
          </p>
          <p>
            <b>IS 15477: 2019</b> Adhesives for Use with Ceramic, Mosaic and
            Stone Tiles—Specification
          </p>
          <p></p>
        </div>

        <div>
          <p>
            Dedicated personnel is regularly carrying out tests and meticulously
            recording results using following available in-house equipment for
            following tests (with equipment):
          </p>

          <div className={styles.container2}>
            <div className={styles.box2}>
              <img src={testing1} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing2} alt="" />

            </div>
            <div className={styles.box2}>
              <img src={testing3} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing4} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing5} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing6} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing7} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing8} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing9} alt="" />

            </div>
            <div className={styles.box2}>
              <img src={testing10} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing11} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing12} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing13} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing14} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing15} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing16} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing17} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing18} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing19} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing20} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing21} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing22} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing23} alt="" />

            </div>

            <div className={styles.box2}>
              <img src={testing24} alt="" />

            </div>


          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default QualityMeasures;
