import Footer from "./Footer";
import Navbar from "./Navbar";

import styles from "./PowerPlant.module.css";

import powerplant1 from "../assests/images/powerplant1.jpg";
import powerplant2 from "../assests/images/powerplant2.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function PowerPlant() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scroll(0, -100)
  }, [pathname])

  return (
    <>
      <Navbar />
      <div className={` mt-[150px] sm:mt-[80px]`}>
        <h1 className={`w-full h-auto text-2xl flex items-center text-[#4c4c4c] justify-center py-[15px]`}>
          <span className={`w-auto h-auto border-b-2 px-[10px] border-b-[#4c4c4c]`}>
            POWER PLANT
          </span>
        </h1>
        <div className={`${styles.powerplantImg} sm:!flex-col sm:items-center`}>
          <img src={powerplant1} className={`sm:w-[300px] sm:!h-auto`} alt="" />
          <img src={powerplant2} className={`sm:w-[300px] sm:!h-auto`} alt="" />
        </div>
        <div className={`px-[30px] my-[30px]`}>
          <p>
            CCPL provides the total solutions to various Boilers comprising
            supply, application, dry out & trouble shooting operation. CCPL
            comprehensive range of monolithic products enables to cater the CFBC
            and other boilers with high performance.
          </p>
          <br />
          <p>
            CCPL products – Abrasion resistance low cement castable, Gunning
            materials, High abrasion resistance andalusite bricks, silicon
            carbide based castables, provide unique solution to various parts of
            a CFBC boiler with various design’s like M /s BHEL, IJT, ALSTOM,
            Cethar vessel, Thermex, Thyssen Krupp etc.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PowerPlant;
