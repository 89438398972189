import styles from "./Performance.module.css";
import monitoring from "../assests/images/tundish-refractories.webp";
import img1 from "../assests/UpdatePhoto/Bhushan_power_steel_logo.png"
import img2 from "../assests/UpdatePhoto/Jindal_stainless_Logo-White.png"
import img3 from "../assests/UpdatePhoto/jsw-steel-logo-82ED4A72F6-seeklogo.com.png"
import img4 from "../assests/UpdatePhoto/SAIL_LOGO_NEW.png"
import img5 from "../assests/UpdatePhoto/aarti_steel_logo.png"
import img6 from "../assests/UpdatePhoto/esl_logo_clrd.png"
import img7 from "../assests/UpdatePhoto/tata-steel-logo.png"
import img8 from "../assests/UpdatePhoto/uttam-value-steels-ltd-85381e9a.webp"
import Fadeimg from "../pages/FadeOnimg/Fadeimg";
import RenderText from "./RenderTextAnimation/RenderText";


function Performance() {
  const data = [
    "Jindal steel and Power Ltd, Angul : 250 Mt steel ladle .",
    "JSW, DOLVI : 180 Mt steel ladle TRM.",
    "Bhushan Power & Steel LTD,Sambalpur : 110 mt Steel ladle.",
    "Jindal Stainless Ltd,Jajpur : 150 Mt steel ladle.",
    "Tata Steel Meramandali: 200 Mt steel ladle.",
    "Aarti Steel Ltd, Cuttack : 40 Mt steel ladle .",
    "IISCO Burnpur : 150 Mt steel ladle.",
    "Electro Steel Ltd, Bokaro: 60 Mt Steel ladle.",
    "Uttam Value Steels ,Wardha: 60 Mt Steel ladle.",
    "Alloy steel Ltd. Durgapur : 60 mt steel ladle.",
    "SAIL RSP, Rourkela : 150 Mt Convertor.",
    "Jindal Stainless Ltd, Jajpur : 150 Mt EAF.",
    "Jindal Stainless Ltd, Hisar : 60 Mt EAF."
  ]

  const image = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8
  ]

  return (
    <div>
      <div className={styles.container}>
        <h2 className={`font-[500]   text-[1.5rem]`} style={{ textAlign: "left" }}>
          <span className={`pr-[20px]`}>PERFORMANCE EVALUATION</span>
        </h2>
        <div className={styles.lineHeight}>
          <RenderText>
            <p className={`flex text-[#4c4c4c] sm:mb-2 sm:text-[15px] items-center justify-start sm:items-start`}>
              <span className={`text-3xl flex items-center sm:items-start justify-center`}>
                ‣
              </span>CLOSE MONITORING & ROOT CAUSE ANALYSIS OF EACH LADLE (IN
              CIRCULATION) IS DONE ON A REGULAR BASIS FOR PROPER FUNCTIONING AND
              BETTER LIFE.
            </p>
            <p className={`flex text-[#4c4c4c] sm:mb-2 items-center sm:text-[15px] sm:items-start justify-start`}>
              <span className={`text-3xl flex items-center justify-center`}>
                ‣
              </span>DAILY FEEDBACK IS COLLECTED BY BACK OFFICE FOR BETTER SITE
              MANAGEMENT AND SMOOTH OPERATION.
            </p>
            <p className={`flex text-[#4c4c4c] sm:mb-2 items-center sm:text-[15px] sm:items-start justify-start`}>
              <span className={`text-3xl flex items-center justify-center`}>
                ‣
              </span>EACH & EVERY PROBLEM IS ADDRESSED BY OUR TECHNICAL TEAM COMPRISING
              OF R&D, QUALITY CONTROL AND QUALITY ASSURANCE DEPARTMENTHEADS
            </p>
          </RenderText>
          <div className={styles.customerList}>
            <h2 className={`font-[500] font-[#4c4c4c] text-[1.5rem]`} style={{ textAlign: "left" }}>
              <span className={`pr-[20px]`} >
                Customers List
              </span>
            </h2>


            <div className={`w-full flex items-start sm:flex-col justify-start gap-[10px] flex-wrap`}>
              {
                data.map((item, index) => {
                  return (
                    <>
                      <div className={`w-[23%] sm:w-full sm:h-auto sm:justify-start sm:items-center cursor-default h-[75px] flex border hover:bg-[#4c4c4c] hover:text-white border-[#4c4c4c]  items-start border-l-[4px] transition-all hover:transition-all border-l-[#4c4c4c] hover:border-b hover:border-b-[#4c4c4c] my-[8px] justify-start   py-[5px] px-[10px]`}>
                        <span className={`w-auto h-auto text-xl p-[5px] flex items-start justify-center`}>
                          {index + 1}
                        </span>
                        <p className={`w-auto h-auto  font-serif`}>
                          {item}
                        </p>
                      </div>
                    </>
                  )
                })
              }

            </div>

            {/* all logo */}
            <marker className={`w-full flex items-center justify-center`}>

              <div className={` w-full sm:justify-center flex flex-wrap items-center justify-start gap-[10px]`}>

                {image.map((item) => {
                  return (
                    <>

                      <img src={item} alt="logo" className={`w-[200px] hover:bg-[#ff000086] transition-all sm:w-[160px] sm:h-[120px] p-[5px] bg-[#ccc] border-black rounded-md h-[150px]`} />

                    </>
                  )
                })}

              </div>
            </marker>

          </div>
          <div className={styles.monitoring}>
            <h1 className={` text-[1.5rem] font-[#4c4c4c] `}>
              <span className={`w-auto h-auto pr-[10px] border-b-2 border-b-[#4c4c4c]`}>
                Achievements in Magnesia Carbon Bricks Supply
              </span>
            </h1>
            <div>
              <RenderText>
                <p>
                  We have supplied ultra low carbon Magnesia carbon bricks to
                  stainless divisions by replacing dolomite in their Teeming
                  Ladle.
                </p>
              </RenderText>
              <RenderText>
                <p>
                  At Jindal stainless Ltd, Hisar Previously they were getting a
                  lining life of 35-50 heats. After using our product they had
                  obtained a straight life of 65 heats and it has been adopted for
                  regular practice. 82 heats is the Highest life achieved by us in
                  teeming ladle till date.
                </p>
              </RenderText>
              <RenderText>
                <p>
                  At Jindal Stainless Ltd, Jajpur we have achieved 87 heats in 150
                  Mt teeming ladle, which is highest heat achieved till date.
                  Previously they were getting a lining life of 50-55 heats .
                </p>
              </RenderText>
              <RenderText>
                <p>
                  We have achieved 1859 Heats for EAF at Jindal Stainless
                  Ltd,Hisar. This is the highest heat achieved till date.
                </p>
              </RenderText>
              <RenderText>
                <p>
                  We have achieved Highest life of 135 Heats at JSW, Dolvi steel
                  ladle TRM.
                </p>
              </RenderText>
            </div>
          </div>

          <div className={styles.monitoring}>
            <h1 className={` text-[1.5rem] font-[#4c4c4c] `}>
              <span className={`w-auto h-auto pr-[10px] border-b-2 border-b-[#4c4c4c]`}>
                For CCM and Induction Furnace Application Area we Supply as Under:
              </span>
            </h1>
            <div>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑴ </span>Slide PLate - 1QC and 2QC
                </p>
              </RenderText>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑵ </span>Laddle Nozzle, Collector Nozzle
                </p>
              </RenderText>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑶ </span>Porous Plug(Ms/SS)
                </p>
              </RenderText>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑷ </span>Mortar -90K
                </p>
              </RenderText>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑸ </span>NFC - Chrome Base
                </p>
              </RenderText>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑹ </span>SIlica and Magnesite Tundish Board, Isoseal,
                  Isopack
                </p>
              </RenderText>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑺ </span>Castable 90, Castable 95
                </p>
              </RenderText>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑻ </span>HA Bricks and Mortar
                </p>
                <p>
                  <span className={`text-2xl`}>⑼ </span>Tundish covering compound
                </p>
              </RenderText>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑽ </span>Mag carbon Bricks for Steel ladles
                </p>
              </RenderText>
            </div>
          </div>

          <div className={styles.monitoring}>
            <h1 className={` text-[1.5rem] font-[#4c4c4c] `}>
              <span className={`w-auto h-auto border-b-2 border-b-[#4c4c4c] pr-[20px]`}>
                For SMS Division we supply as under:
              </span>
            </h1>
            <div>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑴ </span>High Alumina Bricks Range up-to 88%
                </p>
              </RenderText>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑵ </span>Mag- Carbon Bricks
                </p>
              </RenderText>
              <RenderText>
                <p>
                  <span className={`text-2xl`}>⑶ </span>Magnesite /Mag-Chrome / Chrome -Mag Bricks
                </p>
              </RenderText>
              <RenderText>

                <p>
                  <span className={`text-2xl`}>⑷ </span>Castable Conventional and Non-Conventional
                  equivalent to Whytheat A, K & C; 90s,95s; LC- 45,60,70,80,90
                </p>
              </RenderText>
              <RenderText>

                <p>
                  <span className={`text-2xl`}>⑸ </span>EBT Filling Mass
                </p>
              </RenderText>
              <RenderText>

                <p>
                  <span className={`text-2xl`}>⑹ </span>Back Filling Mass
                </p>
              </RenderText>
              <RenderText>

                <p>
                  <span className={`text-2xl`}>⑺ </span>Gunning Mass
                </p>
              </RenderText>
              <RenderText>

                <p>
                  <span className={`text-2xl`}>⑻ </span>Spray Mass
                </p>
              </RenderText>
              <RenderText>

                <p>
                  <span className={`text-2xl`}>⑼ </span>Mortar 90 K/NFC/Casting Powder, Tundish Covering
                  Compound
                </p>
              </RenderText>
              <RenderText>

                <p>
                  <span className={`text-2xl`}>⑽ </span>Turbo Pad and Dam, Impact Pad.
                </p>
              </RenderText>
            </div>
          </div>

          <div className={styles.monitoring}>
            <h1>MONITORING PERFORMANCE -AT SITE</h1>
            <div className={`${styles.monitoringImg} sm:flex sm:items-center sm:justify-center`}>
              <img src={monitoring} alt="monitoring" />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Performance;
