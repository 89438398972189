import { Link } from "react-router-dom";
import styles from "./OurTeamPage.module.css";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import ProductionSlider from "../components/ProductionSlider";
import ManagementSlider from "../components/ManagementSlider";

import profile from "../assests/imgs/user2.png";
import medal from "../assests/imgs/medal.png";
import mergimage from "../assests/MergedImages.png"
import RenderText from "../components/RenderTextAnimation/RenderText";

function OurTeamPage() {
  return (
    <>
      <Navbar />
      <Banner fontsize={'5rem'} img={mergimage} heading="Our Team" />
      <div className={`${styles.infraStructureContent} !mt-0`}>
        {/* <h2 style={{ textAlign: "center" }}>
          <span className={` text-xl font-bold text-[#4c4c4c] px-[10px]`}>Our Team</span>
        </h2> */}

        <div className={styles.flex}>
          <div>
            <ProductionSlider />
          </div>
          <div>
            <ManagementSlider />
          </div>
        </div>
      </div>
      <div className={styles.background}>
        <h3 className={`font-bold !text-[17px]`}>Who we are ?</h3>
        <RenderText>

          <p className={`!text-[15px]`}>
            Champion Ceramics Pvt. Ltd., was established in the year 1987.
            Champion Ceramics Pvt. Ltd., is situated at a very strategic location
            of Central India namely CHAMPA (Chattisgarh) is located on the Howrah
            - Mumbai (via Nagpur) main line of South East Central Railway.
          </p>
        </RenderText>
        <RenderText>

          <p>
            From a humble beginning this unit has now grown into a large-modern
            works manufacturing:- Basic Refractory Products, Fireclay & High
            Alumina Bricks, Acid & Heat Resistance Bricks, Monolithics &
            Castables, Ramming, Gunning & Spray masses, Slide Gate / Flow Control
            Refractories, Tundish Refractories & Special Refractories for
            different categories of industries.
          </p>
        </RenderText>
        <RenderText>

          <p>
            The products of the company enjoy a high reputation for quality and
            durability and are in great demand all over the country.
          </p>
        </RenderText>
        <div className={styles.row}>
          <Link to="profile" className={styles.link}>
            <div className={styles.insideContainer}>
              <img src={profile} alt="profile" />
              <span>Profile</span>
            </div>
          </Link>
          <Link to="quality-measures" className={styles.link}>
            <div className={styles.insideContainer} style={{ border: "none" }}>
              <img src={medal} alt="medal" />
              <span>Quality Measures</span>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurTeamPage;
