import React, { useEffect, useRef, useState } from "react";
import styles from "./Carousel.module.css";

import img1 from "../assests/slider1_update.jpg";
import img2 from "../assests/slider2_update.jpg"
import img3 from "../assests/UpdateSlider5.jpg"
import img4 from "../assests/UpdateSlider4.jpg"
// import img4 from "../assests/"
import { Link } from "react-router-dom";
import Slider from "react-slick";

const Slideshow = () => {
  const ref = useRef()
  const slidecount = [1, 2, 3, 4]
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: true,               // Show dots for navigation
    infinite: true,           // Infinite loop
    speed: 500,               // Transition speed in milliseconds
    slidesToShow: 1,          // Number of slides to show at a time
    slidesToScroll: 1,        // Number of slides to scroll at a time
    autoplay: true,           // Enable auto-sliding
    autoplaySpeed: 1500,      // Delay between slides in milliseconds
    arrows: false,          // Hide arrows
    afterChange: (current) => setCurrentSlide(current),
  };


  // const nextSlide = () => {
  //   setCurrentSlide((prev) => (prev + 1) % slides.length);
  // };

  // const prevSlide = () => {
  //   setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  // };

  // const setSlide = (index) => {
  //   setCurrentSlide(index);
  // };

  return (
    <div className={`mt-[130px] sm:mt-[70px] relative`}>

      <Slider ref={ref} className={`w-full sm:h-[200px] sm:m-0 overflow-hidden h-[480px]`} {...settings}>
        <div className={`w-full h-full`}>
          <img src={img1} className={`w-full h-full overflow-hidden bg-cover sm:object-fill bg-center`} />
        </div>
        <div className={`w-full h-full`}>
          <img src={img2} className={`w-full h-full overflow-hidden bg-center bg-cover sm:object-fill`} />
        </div>
        <div className={`w-full h-full`}>
          <img src={img3} className={`w-full h-full overflow-hidden bg-center bg-cover sm:object-fill`} />
        </div>
        <div className={`w-full h-full`}>
          <img src={img4} className={`w-full sm:object-fill h-full overflow-hidden bg-center bg-cover`} />
        </div>

      </Slider>

      <div className={`w-auto flex items-center justify-center sm:left-[31%] left-[43%] gap-[10px] top-[85%] px-[5px] h-auto absolute py-[5px]`}>
        {slidecount.map((item) => {
          return (
            <>
              <button onClick={() => ref.current.slickGoTo(item - 1)} className={`w-[40px] sm:w-[30px] transition-all sm:h-[30px]  h-[40px] ${currentSlide + 1 == item ? " bg-white opacity-100 fadeanimation text-black transition-all scale-110" : ""}  flex items-center   bg-[#645d4c] opacity-50 text-white rounded-full justify-center p-2`}>
                {item}
              </button>

            </>
          )
        })}
      </div>
    </div>
  );
};

export default Slideshow;
