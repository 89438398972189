import Navbar from "../components/Navbar";
import styles from "./ProductPage.module.css";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import { Link } from "react-router-dom";

import basicRefactory from "../assests/UpdatePhoto/basic-refractories-250x250.webp";
import fireclayRefactory from "../assests/UpdatePhoto/aluminaProduct.webp";
import resistanceRefactory from "../assests/UpdatePhoto/acid-proof-bricks-500x500.webp";
import monolithicRefactory from "../assests/UpdatePhoto/monolithics-500x500.webp";
import rammingRefactory from "../assests/UpdatePhoto/gunning-mass-500x500.webp";
import slideRefactory from "../assests/UpdatePhoto/flowcontrol-500x500.webp";
import tundishRefactory from "../assests/UpdatePhoto/tundish.webp";
import SpecialRefactory from "../assests/UpdatePhoto/special-refractories-bricks-1000x1000.webp";
import bannerimg from "../assests/UpdatePhoto/Untitled_Export_V2.jpeg"
import Fadeimg from "./FadeOnimg/Fadeimg";

function ProductPage() {
  const BasicRefictory = encodeURIComponent('Basic Refractory Products');
  const Fireclay = encodeURIComponent('Fire Clay & High Aluminiam')
  const AcidResistant = encodeURIComponent('Acid and Heat Resistant Products')
  const Castable = encodeURIComponent('CASTABLES AND MONOLITHICS PRODUCTS')
  const Ramming = encodeURIComponent('Ramming&Gunning Spray Masses')
  const SlideGate = encodeURIComponent('Slide Gate & Flow Control Refractories')
  const Tundish = encodeURIComponent('TUNDISH REFRACTORIES')
  const specialRefactory = encodeURIComponent('SPECIAL REFRACTORIES')

  return (
    <>
      <Navbar />
      <Banner img={bannerimg} heading="Product" />
      <div className={styles.infraStructureContent}>
        {/* <h2 className={`text-3xl sm:text-xl mt-[30px]`} style={{ textAlign: "center" }}>
          <span className={`px-[15px] !border-none`}>Product</span>
        </h2> */}

        <div className={`${styles.productContent} my-[30px]`}>
          <div className={styles.infraImg}>
            <Link to={BasicRefictory}>
              <div className={`${styles.box3} sm:w-[150px] sm:h-auto`}>
                <Fadeimg>
                  <img src={basicRefactory} className={` sm:!h-[150px]`} alt="ProductImg" />
                </Fadeimg>
                <div className={`${styles.content3} !text-black  `}>
                  <h3 className={`sm:!text-[15px]`}>Basic Refactory Product</h3>
                </div>
              </div>
            </Link>
            <Link to={Fireclay}>
              <div className={`${styles.box3} sm:w-[150px] sm:h-auto `}>
                <Fadeimg>

                  <img src={fireclayRefactory} className={`sm:!h-[150px]`} alt="ProductImg" />
                </Fadeimg>
                <div className={`${styles.content3} !text-black `}>
                  <h3 className={`sm:!text-[15px]`}>Fireclay & High Alumna</h3>
                </div>
              </div>
            </Link>
            <Link to={AcidResistant}>
              <div className={`${styles.box3} sm:w-[150px] sm:h-auto`}>
                <Fadeimg>

                  <img src={resistanceRefactory} className={`sm:!h-[150px]`} alt="ProductImg" />
                </Fadeimg>
                <div className={`${styles.content3} !text-black `}>
                  <h3 className={`sm:!text-[15px]`}>Acid & Heat Resistance</h3>
                </div>
              </div>
            </Link>
            <Link to={Castable}>
              <div className={`${styles.box3} sm:w-[150px] sm:h-auto`}>
                <Fadeimg>

                  <img src={monolithicRefactory} className={`sm:!h-[150px]`} alt="ProductImg" />
                </Fadeimg>
                <div className={`${styles.content3} !text-black `}>
                  <h3 className={`sm:!text-[15px]`}>Monolithic & Castable</h3>
                </div>
              </div>
            </Link>
            <Link to={Ramming}>
              <div className={`${styles.box3} sm:w-[150px] sm:h-auto`}>
                <Fadeimg>

                  <img src={rammingRefactory} className={`sm:!h-[150px]`} alt="ProductImg" />
                </Fadeimg>
                <div className={`${styles.content3} !text-black `}>
                  <h3 className={`sm:!text-[15px]`}>Ramming/ Gunning Spray Masses</h3>
                </div>
              </div>
            </Link>
            <Link to={SlideGate}>
              <div className={`${styles.box3} sm:w-[150px] sm:h-auto`}>
                <Fadeimg>

                  <img src={slideRefactory} className={`sm:!h-[150px]`} alt="ProductImg" />
                </Fadeimg>
                <div className={`${styles.content3} !text-black `}>
                  <h3 className={`sm:!text-[15px]`}>Slide Gate/ Flow Control Refractories</h3>
                </div>
              </div>
            </Link>
            <Link to={Tundish}>
              <div className={`${styles.box3} sm:w-[150px] sm:h-auto`}>
                <Fadeimg>

                  <img src={tundishRefactory} className={`sm:!h-[150px]`} alt="ProductImg" />
                </Fadeimg>
                <div className={`${styles.content3} !text-black `}>
                  <h3 className={`sm:!text-[15px]`}>Tundish Refractories</h3>
                </div>
              </div>
            </Link>
            <Link to={specialRefactory}>
              <div className={`${styles.box3} sm:w-[150px] sm:h-auto`}>
                <Fadeimg>

                  <img src={SpecialRefactory} alt="ProductImg" className={`sm:!h-[150px]`} />
                </Fadeimg>
                <div className={`${styles.content3} !text-black `}>
                  <h3 className={`sm:!text-[15px]`}>Special Refractories</h3>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProductPage;
