import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import "./RenderTextAnimation.css"

const RenderText = ({ children }) => {
    const [InView, setInview] = useState(false)
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 1
    })

    useEffect(() => {
        setInview(inView)
    }, [inView])

   

    console.log(inView)
    return (
        <>
            <div ref={ref} className={` w-auto h-auto ${!inView && "opacity-0"}`}>
                <div className={`${InView ? "render-text" : " opacity-0"}`}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default RenderText