import React, { useEffect, useState } from "react";
import styles from "./ManagementSlider.module.css";
import productionTeam from "../assests/UpdatePhoto/production team .jpg"
import image1 from "../assests/images/gallery1.webp";
import image2 from "../assests/images/gallery2.webp";
import image3 from "../assests/images/gallery3.webp";
import image4 from "../assests/images/gallery4.webp";
import image5 from "../assests/images/gallery5.webp";
import image6 from "../assests/images/gallery6.webp";
import image7 from "../assests/images/gallery7.webp";
import image8 from "../assests/images/gallery8.webp";
import image9 from "../assests/images/gallery9.webp";
import image10 from "../assests/images/gallery10.webp";
import image11 from "../assests/images/gallery11.webp";
import image12 from "../assests/images/gallery12.webp";
import Fadeimg from "../pages/FadeOnimg/Fadeimg";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
];

function ManagementSlider() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };


  return (
    <>
      <div className={styles.slider}>
        <div className={styles.slideContainer}>
          <h1 className={`text-xl text-[#4c4c4c] border-b-2 border-b-[#4c4c4c] sm:text-[17px] sm:px-[10px]   py-[10px] font-bold `}>
            <span>PRODUC</span>TION TEAM
          </h1>
          <div className={styles.slide}>
            <Fadeimg>
              <img
                className={styles.image}
                src={productionTeam}
                alt={`Slide ${currentImageIndex}`}
              />
            </Fadeimg>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagementSlider;
