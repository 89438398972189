import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import Fadeimg from "./FadeOnimg/Fadeimg";
import styles from "./RefactorApplicationPage.module.css";
import dri from "../assests/images/application1-66547d67dc17d.webp";
import lrf from "../assests/images/application2-66547d6725eaf.webp";
import eaf from "../assests/images/application3-66547d68ae4e8.webp";
import RenderText from "../components/RenderTextAnimation/RenderText";
import img from "../../src/assests/UpdatePhoto/ImageForArticle_1408(1).webp"
import img1 from "../../src/assests/UpdatePhoto/Change.webp"


function RefactorApplicationPage() {
  return (
    <>
      <Navbar />
      <Banner fontsize={'5rem'} heading="Refactor Application" />
      <div className={styles.infraStructureContent}>


        <div className={styles.infraImg}>
          <div className={`${styles.box3} translate-y-0 hover:translate-y-50  `}>

            <img src={img} alt="workerImg" />

            <div className={` w-full h-auto px-[10px] py-[5px] text-xl absolute bottom-0 bg-gradient-to-r from-white to-slate-600`}>

              <h3>DRI</h3>
            </div>

          </div>
          <div className={styles.box3}>

            <img src={img1} alt="workerImg" />

            <div className={` w-full h-auto px-[10px] py-[5px] text-xl absolute bottom-0 bg-gradient-to-r from-white to-slate-600`}>
              <h3>LRF</h3>
            </div>
          </div>
          <div className={styles.box3}>

            <img src={eaf} alt="workerImg" />


            <div className={` w-full h-auto px-[10px] py-[5px] text-xl absolute bottom-0 bg-gradient-to-r from-white to-slate-600`}>
              <h3>EAF</h3>
            </div>
          </div>
        </div>
        <div className={styles.paragrap}>
          <RenderText>
            <p>
              Champion Ceramics Pvt. Ltd., was established in the year 1987.
              Champion Ceramics Pvt. Ltd., is situated at a very strategic
              location of Central India namely CHAMPA (Chattisgarh) is located on
              the Howrah - Mumbai (via Nagpur) main line of South East Central
              Railway
            </p>
          </RenderText>
          <RenderText>
            <p>
              From a humble beginning this unit has now grown into a large-modern
              works manufacturing:- Basic Refractory Products, Fireclay & High
              Alumina Bricks, Acid & Heat Resistance Bricks, Monolithics &
              Castables, Ramming, Gunning & Spray masses, Slide Gate / Flow
              Control Refractories, Tundish Refractories & Special Refractories
              for different categories of industries. The products of the company
              enjoy a high reputation for quality and durability and are in great
              demand all over the country.
            </p>
          </RenderText>
          <RenderText>
            <p>
              We are proud of our heritage and long association with refractory
              consuming industries.
            </p>
          </RenderText>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RefactorApplicationPage;
