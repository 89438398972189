import { Link } from "react-router-dom";
import styles from "./AppNav.module.css";

import aboutImg from "../assests/imgs/about-us.svg";
import infrastructureImg from "../assests/imgs/building.png";
import refactorApplicationImg from "../assests/imgs/reactor.png";
import productImg from "../assests/imgs/product.png";
import teamImg from "../assests/imgs/team.png";
import clientImg from "../assests/imgs/client.png";
import contactImg from "../assests/imgs/contact-us.png";
import homeImg from "../assests/imgs/home.png";

function AppNav({ setShowMenu }) {
  return (
    <div className={styles.background} onClick={() => setShowMenu(false)}>
      <div className={styles.appNav}>
        <div className={styles.inputSearch}>
          <input type="search" placeholder="Search" />
        </div>
        <Link to="/" className={styles.navlink}>
          <li>
            <span>
              <img src={homeImg} alt="" />
            </span>
            Home
          </li>
        </Link>
        <Link to="/aboutus" className={styles.navlink}>
          <li>
            <span>
              <img src={aboutImg} alt="" />
            </span>
            About Us
          </li>
        </Link>
        <Link to="/infrastructure" className={styles.navlink}>
          <li>
            <span>
              <img src={infrastructureImg} alt="" />
            </span>
            Infrastructure
          </li>
        </Link>
        <Link to="/refactorapplication" className={styles.navlink}>
          <li>
            <span>
              <img src={refactorApplicationImg} alt="" />
            </span>
            Refactor Application
          </li>
        </Link>
        <Link to="/product" className={styles.navlink}>
          <li>
            <span>
              <img src={productImg} alt="" />
            </span>
            Product
          </li>
        </Link>
        <Link to="/ourteam" className={styles.navlink}>
          <li>
            <span>
              <img src={teamImg} alt="" />
            </span>
            Our Team
          </li>
        </Link>
        <Link to="/ourclients" className={styles.navlink}>
          <li>
            <span>
              <img src={clientImg} alt="" />
            </span>
            Our Clients
          </li>
        </Link>
        <Link to="/contactus" className={styles.navlink}>
          <li>
            <span>
              <img src={contactImg} alt="" />
            </span>
            Contact Us
          </li>
        </Link>
      </div>
    </div>
  );
}

export default AppNav;
