import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import bannerimg from "../assests/Basic_refectroy.jpg";
import styles from "./ProductItem.module.css";
import Footer from "./Footer";
import acid from "../assests/imgs/acid1.jpg";
import acid2 from "../assests/imgs/acid2.jpg";
import Banner from "./Banner";
import fireclayalumina from "../assests/product2.jpg"
import Acidheatresistant from "../assests/acidHeatResistant.jpg"
import CastablesBannerImg from "../assests/monolothics.jpg"
import RammingBimg from "../assests/rummy.jpg"
import ProductItemBanner from "./ProductItemBanner";
import FlowControl from "../assests/slide_control_reflectories.jpg"
import Tundish_control from "../assests/tundishReflectories.jpg"
import SpecialRefactory from "../assests/special-shape-refractories-bricks.jpg"


const mockProducts = [
  {
    id: "Basic Refractory Products",
    name: "Basic Refactory Product",
    description: "Description of Product 1",
    img1: acid,
    img2: acid2,
    bannerimg: bannerimg
  },
  {
    id: "Fire Clay & High Aluminiam",
    name: "Fireclay & High Alumna",
    description: "Description of Product 2",
    bannerimg: fireclayalumina
  },
  {
    id: "Acid and Heat Resistant Products",
    name: "Acid & Heat Resistance",
    bannerimg: Acidheatresistant,
    description:
      "Deutsche Edelstahlwerke and Ugitech, our production plants rank among the leading manufacturers of stainless steel and acid- and heat-resistant steels.Our stainless, acid- and heat-resistant steels satisfy the highest technical requirements such as extreme resistance to corrosion, mechanical load, high strength, strain values and very high thermal resistance. We provide a range of effective solutions in all dimensions, right up to ready-to-use components:Austenitic, ferritic, martensitic and duplex grades including high machinability versionsDeutsche Edelstahlwerke and Ugitech, our production plants rank among the leading manufacturers of stainless steel and acid- and heat-resistant steels.Our stainless, acid- and heat-resistant steels satisfy the highest technical requirements such as extreme resistance to corrosion, mechanical load, high strength, strain values and very high thermal resistance. We provide a range of effective solutions in all dimensions, right up to ready-to-use components:Austenitic, ferritic, martensitic and duplex grades including high machinability versions",
    img1: acid,
    img2: acid2,
  },
  {
    id: "CASTABLES AND MONOLITHICS PRODUCTS",
    bannerimg: CastablesBannerImg
  },
  {
    id: "Ramming&Gunning Spray Masses",
    bannerimg: RammingBimg
  },
  {
    id: "Slide Gate&Flow Control Refractories",
    bannerimg: FlowControl
  },
  {
    id: "TUNDISH REFRACTORIES",
    bannerimg: Tundish_control
  },
  {
    id: "SPECIAL REFRACTORIES",
    bannerimg: SpecialRefactory
  }
];

function ProductItem() {
  const { id } = useParams();
  const navigate = useNavigate()
  const location = useLocation()

  // column for refactor
  const RefactoryColumn = [
    {
      title: "MAGNESIA BRICKS",
      thead: [
        "Product",
        "MgO%",
        "CaO%",
        "SiO2%",
        "Fe2o3%",
        "Al2O3%",
        "AP%",
        "Ccs kg/cm2",
        "Rul(Ta)0c Max",
        "Application",
      ],
      tbody: [
        [
          "CC-MGR",
          "87.00",
          "2.00",
          "6.50",
          "-",
          "1.00",
          "22",
          "350",
          "1550",
          "EAF, LD & Ladle Backup"
        ],
        [
          "CC-MGD",
          "92.00",
          "2.00",
          "4.00",
          "-",
          "0.80",
          "18",
          "600",
          "1600",
          "Hot Metal Mixer"
        ],
        [
          "CC-M-96",
          "96.00",
          "2.00",
          "1.00",
          "0.50",
          "0.30",
          "17",
          "500",
          "1700",
          "Glass Tank Regenerator"
        ]
      ]
    },
    {
      title: "MAGNESIA CHROME BRICKS",
      thead: [
        "Product",
        "Mg0%",
        "Cr203%",
        "SiO2%",
        "A.P%",
        "CCS Kg/cm2",
        "RUL (Ta)C Max",
        "Application"
      ],
      tbody: [
        [
          "CC-MGR",
          "63",
          "11",
          "6.30",
          "22",
          "300",
          "1600",
          "Open Hearth Roof"
        ],
        [
          "CC-MC40S3",
          "60",
          "15",
          "3.00",
          "20",
          "300",
          "1700",
          "Rotary Kiln for dead Buring Dolomite",

        ],
        [
          "CC-CMN",
          "35",
          "22",
          "-",
          "22",
          "250",
          "1600",
          "Backup Lining"
        ],
      ]
    },
    {
      title: "DIRECT BONDED MAGNESIA CHROME BRICKS",
      thead: [
        "Product",
        "MgO%",
        "Cr2O3%",
        "SiO3%",
        "A.P%",
        "B.D gm/cc",
        "CCS Kg/cm2",
        "RUL (Ta)0C",
        "Application"
      ],
      tbody: [
        [
          "CC-CR-VX",
          "58",
          "20",
          "0.8",
          "18",
          "3.15",
          "400",
          "1720",
          "Copper,Glass, VOD & Zinc Furnaces"
        ],
        [
          "CC-CR-VV",
          "60",
          "18",
          "1.5",
          "18",
          "3.00",
          "400",
          "1700",
          "Copper,Glass, VOD & Zinc Furnaces"
        ],
        [
          "CC-CR-XV",
          "55",
          "24",
          "1.0",
          "17",
          "3.18",
          "550",
          "1750",
          "Copper smelting & converting Furnaces"
        ],
        [
          "CC-RH",
          "58",
          "18",
          "1.0",
          "18",
          "3.15",
          "400",
          "1700",
          "Snorkel & Lower vessel of RH degasser"
        ]
      ]
    },
    {
      title: "MAGNESIA CARBON BRICKS",
      thead: [
        "Product",
        "MgO%",
        "F.C%",
        "A.P%",
        "B.D gm/cc",
        "CCS kg/cm2",
        "HMOR kg/cm2",
        "Application"
      ],
      tbody: [
        [
          "CC-HMR-LD",
          "97",
          "8",
          "5",
          "3",
          "300",
          "90",
          "LD Convertor Bottom & Tap Pad"
        ],
        [
          "CC-HMR-SL",
          "97",
          "10",
          "5",
          "3",
          "300",
          "-",
          "Steel Ladle"
        ]
      ]
    },
    {
      title: "ALUMINA MAGNESIA CARBON BRICKS",
      thead: [
        "Product",
        "Al2O3 %",
        "MgO%",
        "FC%",
        "A.P%",
        "B.D gm/cc",
        "CCS kg/cm2",
        "Application"
      ],
      tbody: [
        [
          "CC-AMC-1",
          "70",
          "8",
          "5",
          "6",
          "2.8",
          "400",
          "Steel Ladle Bottom & Metal Zone"
        ],
        [
          "CC-AMC-3",
          "80",
          "8",
          "5",
          "5",
          "3.1",
          "400",
          "Steel Ladle Bottom & Metal Zone"
        ]
      ]
    },
    {
      title: "BASIC MORTARS",
      thead: [
        "Product",
        "MgO%",
        "Cr2oO3%",
        "SiO2%",
        "Setting",
        "Granding mm",
        "Sintering Temp Deg c",
        "CCS Kg/cm2",
        "Application"
      ],
      tbody: [
        [
          "CC-MGRM",
          "85",
          "-",
          "8",
          "Ceramic",
          "0-0.5",
          "1600",
          "-",
          "Laying Magnesite Bricks"
        ],
        [
          "CC-MGW (I) M",
          "92",
          "-",
          "2",
          "-do-",
          "0-0.5",
          "1650",
          "-",
          "Laying hing Magnesia Bricks"
        ],
        [
          "CC-MCM",
          "60",
          "15",
          "-",
          "-do-",
          "0-0.5",
          "1600",
          "-",
          "Laying Magnesia Chorme Bricks",
        ],
        [
          "CC-CMM",
          "40",
          "25",
          "-",
          "-do-",
          "0-0.5",
          "1600",
          "-",
          "Laying Chorme Magnesia Bricks",
        ],
        [
          "CC-DBM/CM",
          "65",
          "15",
          "2",
          "-do-",
          "0-0.5",
          "1600",
          "-",
          "Laying DBMC Bricks"
        ],
        [
          "CC-CH-15",
          "15",
          "35",
          "6",
          "Hydraulic",
          "0.5",
          "1300",
          "200",
          "Copper and other applications"
        ]
      ]
    },
    {
      title: "BASIC RAMMING MASSES",
      thead: [
        "Product",
        "Mg0%",
        "SiO2%",
        "F32O3%",
        "Setting",
        "Gradding mm",
        "Sintering Temp Deg C",
        "Application on Temp Deg C",
        "Application"
      ],
      tbody: [
        [
          "CC-M-95",
          "94",
          "1.54",
          "-",
          "Chemical",
          "0-5",
          "1550",
          "1750",
          "Tap Hole of BOF"
        ]
      ]
    },
    {
      title: "BASIC GUNNING MASSES",
      thead: [
        "Product",
        "MgO%",
        "SiO2%",
        "Setting",
        "Grading mm",
        "PCE (SK)",
        "B.D gm/cc",
        "CCS kg/cm2",
        "PLC%",
        "CCS kg/cm2"
      ],
      tbody: [
        [
          "CC-Gun-I",
          "80",
          "9.0",
          "Chemical",
          "0-3",
          "38",
          "-",
          "-",
          "-2.0",
          "200"
        ],
        [
          "CC-Gun",
          "85",
          "8.0",
          "Chemical",
          "0-3",
          "38",
          "2.70",
          "350",
          "-1.5",
          "300"
        ],
        [
          "CC-Gun-Super",
          "90",
          "4.0",
          "Chemical",
          "0-3",
          "38",
          "2.75",
          "350",
          "-1.5",
          "200",
        ],
        [
          "CC-Gun-Super-SPL",
          "94",
          "2.0",
          "Chemical",
          "0-3",
          "38",
          "2.80",
          "400",
          "-1.5",
          "200"
        ]
      ]

    },
    {
      title: "BASIC SPRAYING MASSES",
      thead: [
        "Product",
        "MgO%",
        "Sio2%",
        "Fe2So3%",
        "Setting",
        "Gradding mm",
        "Sintering Temp in oC",
        "Appln. Temp in oC",
        "PLC",
        "B.D gm/cc",
        "Application Area"
      ],
      tbody: [
        [
          "CC/87-70",
          "66",
          "25",
          "5.5",
          "Chemical",
          "0-0.5",
          "750",
          "1600",
          "-3.0",
          "1.85",
          "Spraying of bottom and walls of tundish"
        ],
        [
          "CC/87-80",
          "78",
          "14",
          "5.0",
          "Chemical",
          "0.0.5",
          "750",
          "1650",
          "-5.0",
          "1.85",
          "Spraying of bottom and walls Of tundish"
        ],
        [
          "CC/87-90",
          "85",
          "7",
          "3.0",
          "Chemical",
          "0-0.5",
          "750",
          "1650",
          "-5.0",
          "1.85",
          "Spraying of bottom and walls Of tundish"
        ],
        [
          "CC-VRC-80",
          "78",
          "14",
          "5.0",
          "Chemical",
          "0-1",
          "750",
          "1650",
          "-1.5",
          "2.35",
          "Dry vibratable  mix for botton and wall of tundish"
        ]
      ]
    }

  ]


  // column for fireclay
  const Fireclayandhighalumina = [
    {
      title: "FIRE CLAY AND HIGH ALUMINA BRICKS",
      thead: [
        "Product",
        "Al2O3%",
        "FeO3%",
        "A.P%",
        "B.D gm/cc",
        "CCS Kg/cm2",
        "PLC %",
        "PCE(SK)",
        "RUL(TA)C",
        "Application Area"
      ],
      tbody: [
        [
          "CC-BS(B)",
          "36",
          "1.70",
          "18",
          "2.15",
          "300",
          "±0.30 at 1450oC/2 hrs",
          "31",
          "1450",
          "Blast Furnace Stack Lining"
        ],
        [
          "CC-42D",
          "42",
          "1.50",
          "16",
          "2.25",
          "400",
          "±0.5 at 1450oC/2 hrs",
          "33",
          "1500",
          "Blast Furnace Lining"
        ],
        [
          "CC-45",
          "45",
          "1.50",
          "21",
          "2.20",
          "350",
          "±0.50 at 1500oC/2 hrs",
          "34",
          "1450",
          "Blast Furnace Lining, Cement Rotary Kiln, Lime Calcination Kiln, Glass Tank Furnace"
        ],
        [
          "CC-45D",
          "45",
          "1.50",
          "16",
          "2.30",
          "450",
          "±0.30 at 1450oC/2 hrs",
          "34",
          "1500",
          "Blast Furnace Lining, Cement Rotary Kiln, Lime Calcination Kiln, Glass Tank Furnace"
        ],
        [
          "CC-CB",
          "50",
          "1.30",
          "18",
          "2.35",
          "350",
          "±0.30 at 1500oC/2 hrs",
          "34",
          "1530",
          "Anode baking furnace"
        ],
        [
          "CC-CHL",
          "50",
          "1.5",
          "9",
          "2.42",
          "700",
          "-",
          "-",
          "1550",
          "Chlorinator"
        ],
        [
          "CC-55D",
          "55",
          "1.50",
          "18",
          "2.40",
          "500",
          "±0.50< at 1450oC/2 hrs",
          "35",
          "1520",
          "Blast Furnace Lining, Cement Rotary Kiln, Lime Calcination Kiln, Glass Tank Furnace"
        ],
        [
          "CC-HIAL59",
          "59",
          "1.20",
          "18",
          "2.50",
          "500",
          "±0.50 at 1450oC/2 hrs",
          "36",
          "1600",
          "BF hot blast main, Glass tank furnace"
        ],
        [
          "CC-HIAL 50",
          "50",
          "1.10",
          "18",
          "2.40",
          "400",
          "±0.5 at 1450oC/4 hrs",
          "34",
          "1520",
          "Anode baking furnace"
        ],
        [
          "CC-AL65-SIC",
          "65",
          "1.50",
          "12",
          "2.65",
          "400",
          "-",
          "36",
          "1650",
          "Torpedo Ladel"
        ],
        [
          "CC 45B",
          "45",
          "3.5",
          "22",
          "2.25",
          "300",
          "±1.5 at 1350oC/2 hrs",
          "33",
          "1350",
          "Cement Plants"
        ],
        [
          "CC 50B ",
          "50",
          "3.5",
          "22",
          "2.30",
          "350",
          "±1.5 at 1400oC/2 hrs",
          "33",
          "1370",
          "Cement Plants"
        ],
        [
          "CC-60B LF",
          "60",
          "2.52",
          "22",
          "2.45",
          "450",
          "±2.0 at 1450oC/2 hrs",
          "35",
          "1420",
          "-"
        ],
        [
          "CC-60B",
          "60",
          "3.00",
          "22",
          "2.40",
          "400",
          "±2.0 at 1450oC/2 hrs",
          "34",
          "1400",
          "-"
        ],
        [
          "CC-62",
          "62",
          "1.50",
          "22",
          "2.40",
          "350",
          "±0.40 at 1500oC/2 hrs",
          "36",
          "1500",
          "Blast Furnace Stove Checkers, BF lining"
        ],
        [
          "CC-62D",
          "62",
          "1.25",
          "16",
          "2.50",
          "600",
          "±0.20 at 1500oc/2hrs",
          "36",
          "1550",
          "Blast Furnace Hearth & tuyere"
        ],
        [
          "CC-HIAL 62",
          "62",
          "1.2",
          "18",
          "2.52",
          "500",
          "±0.3 at 1500oc/2hrs",
          "36",
          "1600",
          "Blast furnace checkers"
        ],
        [
          "CC-70",
          "70",
          "1.5",
          "20",
          "2.60",
          "500",
          "±0.3 at 1500oc/2hrs",
          "36",
          "1550",
          'Rotary kiln Lining',
        ],
        [
          "CC-Mul-70",
          "70",
          "0.80",
          "20",
          "2.50",
          "500",
          "±0.20 at 1550oc/2hrs",
          "36",
          "1700",
          "Blast Furnace Hearth & Tap hole, Glass Tank Furnace"
        ],
        [
          "CC-Mul-70(F)",
          "70",
          "0.5",
          "17",
          "2.55",
          "600",
          "±0.20 at 1550oc/2hrs",
          "36",
          "1720",
          "Blast Furnace Hearth & Tap hole, Glass Tank Furnace"
        ],
        [
          "CC-70B (LF)",
          "70",
          "2.5",
          "20",
          "2.60",
          "500",
          "±3.0 at 1600oc/2hrs",
          "36",
          "1480",
          "EAF Roof, Ladle"
        ],
        [
          "CC-70B",
          "70",
          "3.0",
          "22",
          "2.55",
          "450",
          "±3.0 at 1450oc/2hrs",
          "35",
          "1440",
          "EAF Roof"
        ],
        [
          "CC-80B (LF)",
          "80",
          "2.5",
          "20",
          "2.70",
          "500",
          "±3.0 at 1600oc/2hrs",
          "37",
          "1500",
          "EAF Roof",
        ],
        [
          "CC-80B",
          "80",
          "3.0",
          "22",
          "2.70",
          "500",
          "±3.0 at 1600oc/2hrs",
          "35",
          "1460",
          "EAF Roof",
        ],
        [
          "CC-85CBC",
          "84",
          "1.5",
          "20",
          "2.70",
          "700",
          "±0.5 at 1500oc/2hrs",
          "37",
          "1580",
          "Aluminium Melting & Holding Furnace",
        ],
        [
          "CC-85DG",
          "85",
          "1.5",
          "20",
          "2.90",
          "500",
          "±0.5 at 1500oc/2hrs",
          "37",
          "1650",
          "Torpedo Ladle lining"
        ],
        [
          "CC-88X",
          "88",
          "2.0",
          "18",
          "2.85",
          "800",
          "±0.5 at 1500oc/2hrs ",
          "37",
          "1580",
          "Reheating furnace hearth"
        ],
        [
          "CC-90XD",
          "90",
          "0.5",
          "18",
          "2.92",
          "600",
          "±0.2 at 1500oc/2hrs",
          "38",
          "1700",
          "Carbon black reactor"
        ],
        [
          "CC-95",
          "95",
          "0.5",
          "22",
          "3.00",
          "600",
          "±0.2 at 1500oc/2hrs",
          "38",
          "1700",
          "Secondary Steel making Vessel, chemical, petrochemical & Fertilizer application"
        ],
        [
          "CC-99C",
          "99",
          "0.1",
          "18",
          "3.00",
          "750",
          "±0.3 at 1500oc/2hrs",
          "38",
          "1700",
          "Secondary Steel making Vessel, chemical, petrochemical & Fertilizer application"
        ]
      ]
    },

    {
      title: "FIRE CLAY AND HIGH ALUMINA BRICKS FOR NON-RECOVERY COKE OVEN",
      thead: [
        "Product",
        "Al2O3%",
        "Fe2O3%",
        "A.P%",
        "CCS kg/CM2",
        "PLC%",
        "RUL (Ta)C",
        "PCE (C)"
      ],
      tbody: [
        [
          "CC-NRC-(40)",
          "38%",
          "2.0",
          "24",
          "250",
          "+1.0-0.5",
          "1350",
          "1740",
        ],
        [
          "CC-NRC-(50)",
          "48.0",
          "1.5",
          "22",
          "400",
          "+1.0-0.4",
          "1420",
          "1750"
        ],
        [
          "CC-NRC-(55)",
          "55.0",
          "1.5",
          "22",
          "450",
          "+0.1-0.4 (1500®C/2 hrs)",
          "1470",
          "1770"
        ],
        [
          "CC-NRC-(65)",
          "65.0",
          "1.5",
          "23",
          "500",
          "+0.1-0.4 (1500®C/2 hrs)",
          "1500",
          "1790"
        ]
      ]
    },
    {
      title: "FIRE CLAY AND HIGH ALUMINA CERAMIC SETTING MORTARS",
      thead: [
        "Products",
        "Setting",
        "Sintering Temp. (®C)",
        "Grading (mm)",
        "ApplicationTemp. (®C)",
        "Al2O3%",
        "Fe2O3%",
        "PCE (SK)",
        "Application Area"
      ],
      tbody: [
        [
          "CC-HGM",
          "Ceramic",
          "1300",
          "0-0.5",
          "1400",
          "33",
          "2.5",
          "32-33",
          "Laying HG Bricks"
        ],
        [
          "CC-45M",
          "Ceramic",
          "1350",
          "0-0.5",
          "1450",
          "42",
          "2.0",
          "33",
          "Laying 42-45% Alumina Bricks"
        ],
        [
          "CC-60M",
          "Ceramic",
          "1350",
          "0-0.5",
          "1500",
          "60",
          "2.0",
          "35",
          "Laying 50-60% Alumina Bricks"
        ],
        [
          "CC-70M",
          "Ceramic",
          "1350",
          "0-0.5",
          "1550",
          "70",
          "2.0",
          "35",
          "Laying 70% Alumina Bricks"
        ],
        [
          "CC-mul-70M",
          "Ceramic",
          "1400",
          "0-0.5",
          "1600",
          "70",
          "1.5",
          "36",
          "Laying Mullite Bricks"
        ],
        [
          "CC-80M",
          "Ceramic",
          "1400",
          "0-0.5",
          "1650",
          "80",
          "2.5",
          "36",
          "Laying 80% Alumina Bricks",

        ],
        [
          "CC-90M",
          "Ceramic",
          "1400",
          "0-0.5",
          "1700",
          "90",
          "2.0",
          "36",
          " Laying 90% Alumina Bricks"
        ]

      ]
    },
    {
      title: "FIRE CLAY AND HIGH ALUMINA CHEMICAL SETTING MORTAR",
      thead: [
        "Products",
        "Setting",
        "Sintering Temp. (®C)",
        "Grading(mm)",
        "ApplicationTemp. (®C)",
        "Al2O3%",
        "Fe2O3%",
        "PCE(SK)",
        "Application Area"
      ],
      tbody: [
        [
          "CC-set-50 (F)",
          "Air",
          "1100",
          "0-0.5",
          "1550",
          "50",
          "4",
          "32",
          "Laying 45-50% alumina bricks"
        ],
        [
          "CC-set-50 (N)",
          "Air",
          "1100",
          "0-1",
          "1550",
          "50",
          "4",
          "32",
          "Laying 45-50% alumina bricks"
        ]
      ]
    },
    {
      title: "FIRE CLAY AND HIGH ALUMINA PLASTIC MASSES",
      thead: [
        "Products",
        "Setting",
        "Sintering Temp. (®C)",
        "Grading(mm)",
        "ApplicationTemp. (®C)",
        "Al2O3%",
        "Fe2O3%",
        "PCE(SK)",
        "Application Area"
      ],
      tbody: [
        [
          "CC-plast-50",
          "Chemical",
          "1100",
          "0-5",
          "1600",
          "50",
          "1.0",
          "35",
          "Incinerators for medium & high temperature applicaton with high strength at intermediate temperature"
        ],
        [
          "CC-plast-80",
          "Chemical",
          "1100",
          "0-5",
          "1750",
          "80",
          "1.5",
          "38",
          "Steel & Aluminium Furnace"
        ],
        [
          "CC-plast-90",
          "Chemical",
          "1100",
          "0.5",
          "1750",
          "88",
          "0.5",
          "38",
          "Silver Melting furnace & Acid regeneration plant"
        ]
      ]
    },
    {
      title: "FIRE CLAY AND HIGH ALUMINA CASTABLES",
      thead: [
        "Products",
        "Al2O3%",
        " Fe2O3%",
        "B.D gm/cc",
        "Dried at110®c/24 hrs",
        "Fired at 1400®c/3 hrs",
        "Fired at 1500®c/3 hrs",
        "PLC%",
        "PCE(®C)",
        "Grain Size(mm)"
      ],
      tbody: [
        [
          "Champcast-FH-14",
          "40",
          "2.5",
          "1.9",
          "250",
          "350",
          "-",
          "±1.5(1400®C/3h)",
          "1580",
          "0-5"
        ],
        [
          "Champcast-FH-45",
          "45",
          "4",
          "2.1",
          "250",
          "350",
          "-",
          "±1.0(1400®C/3h)",
          "1580",
          "0-5",
        ],
        [
          "Champcast-FH-70",
          "70",
          "5",
          "2.5",
          "350",
          "450",
          "-",
          "±1.0(1400®C/3h)",
          "1680",
          "0-5",

        ],
        [
          "Champcast-FH-50",
          "50",
          "1.5",
          "2.1",
          "350",
          "250",
          "400",
          "±1.0(1500®C/3h)",
          "1660",
          "0-5"
        ],
        [
          "Champcast-FH-50 PLUS",
          "50",
          "1",
          "2.1",
          "500",
          "350",
          "500",
          "±1.0(1500®C/3h)",
          "1700",
          "0-5"
        ],
        [
          "Champcast-FH-60",
          "60",
          "1",
          "2.2",
          "350",
          "250",
          "450",
          "±1.5(1500®C/3h)",
          "1680",
          "0-5"
        ],
        [
          "Champcast-FH-60 PLUS",
          "60",
          "1",
          "2.2",
          "500",
          "350",
          "500",
          "±1.0(1500®C/3h)",
          "1760",
          "0-5"
        ],
        [
          "Champcast-FH-70",
          "70",
          "1.5",
          "2.5",
          "500",
          "300",
          "350",
          "±1.5(1500®C/3h)",
          "1760",
          "0-5"
        ],
        [
          "Champcast-FH-80",
          "80",
          "1.5",
          "2.6",
          "550",
          "300",
          "±1.5(1500®C/3h)",
          "1780",
          "0-5"
        ],
        [
          "Champcast-FH-90",
          "88",
          "1.5",
          "2.75",
          "550",
          "300",
          "550",
          "±1.5(1500®C/3h)",
          "1780",
          "0-5"
        ],
        [
          "Champcast-FH-90 PLUS",
          "90",
          "1.0",
          "2.8",
          "600",
          "300",
          "550",
          "±1.5(1550®C/3h)",
          "1800",
          "0-5"
        ]
      ]
    }
  ]


  // ACID AND HEAT RESISTANT
  const acidandheatresistant = [
    {
      title: "ACID RESISTANCE BRICKS (CONFIRMING to IS:4860)",
      thead: [
        "Properties",
        "Unit",
        "Class-I Bricks",
        "Class-II Bricks",
        "Application"
      ],
      tbody: [
        [
          "Water Absorption",
          "% Max",
          "2",
          "4",
          "Chemical Process Floor, Chimneys, Tanks etc."
        ],
        [
          "Flexural Strenght",
          "Kg/cm2",
          "100",
          "70",
          "Chemical Process Floor, Chimneys, Tanks etc."
        ],
        [
          "Compressive Strength",
          "Kg/cm2",
          "+700",
          "+500",
          "Chemical Process Floor, Chimneys, Tanks etc."
        ],
        [
          "Resistance to Acid",
          "Loss in weight % Max",
          "1.5",
          "4.0",
          "Chemical Process Floor, Chimneys, Tanks etc."
        ]
      ]
    },
    {
      title: "ACID RESISTANCE TILES (CONFIRMING to IS:4457)",
      thead: [
        "Properties",
        "Unit",
        "Class-I Bricks",
        "Application"
      ],
      tbody: [
        [
          "Water Absorption",
          "% Max",
          "2",
          "Various Application specially in Chemical, Petrochemical and Fertilizer Industries."
        ],
        [
          "Flexural Strenght",
          "Kg/cm2",
          "200",
          "Various Application specially in Chemical, Petrochemical and Fertilizer Industries."
        ],
        [
          "Compressive Strength",
          "Kg/cm2",
          "+700",
          "Various Application specially in Chemical, Petrochemical and Fertilizer Industries."
        ],
        [
          "Resistance to Acid",
          "Loss in weight % Max",
          "1.5",
          "Various Application specially in Chemical, Petrochemical and Fertilizer Industries."

        ]
      ]
    },
    {
      title: "SODIUM SILICATE MORTAR (CONFIRMING to IS:4832)",
      thead: [
        "Properties",
        "Unit",
        "Class-I Bricks",
        "Application"
      ],
      tbody: [
        [
          "Working Time",
          "Minutes",
          "20",
          "For laying of Fire Resistant Bricks"
        ],
        [
          "Flexural Strength",
          "Kg/cm2",
          "35",
          "For laying of Fire Resistant Bricks"
        ],
        [
          "Compressive Strength",
          "Kg/cm2",
          "100",
          "For laying of Fire Resistant Bricks"
        ],
        [
          "Bond Strength",
          "Kg/cm2",
          "5",
          "For laying of Fire Resistant Bricks"
        ],
        [
          "Absorption of Toulene",
          "% Max",
          "18",
          "For laying of Fire Resistant Bricks"
        ],

      ]
    },
    {
      title: "POTASSIUM SILICATE MORTAR (CONFIRMING to IS:4832)",
      thead: [
        "Properties",
        "Unit",
        "Class-I Bricks",
        "Application"
      ],
      tbody: [
        [
          "Working Time",
          "Minutes",
          "20",
          "For laying of Acid Resistant Bricks"
        ],
        [
          "Flexural Strength",
          "Kg/cm2",
          "40",
          "For laying of Acid Resistant Bricks"
        ],
        [
          "Compressive Strength",
          "Kg/cm2",
          "150",
          "For laying of Acid Resistant Bricks"
        ],
        [
          "Bond Strength",
          "Kg/cm2",
          "5",
          "For laying of Acid Resistant Bricks"
        ],
        [
          "Absorption of Toulene",
          "% Max",
          "18",
          "For laying of Acid Resistant Bricks"
        ]
      ]
    }
  ]


  // CASTABLES
  const CASTABLES = [
    {
      title: "CONVENTIONAL AND HIGH PURITY DENSE CASTABLES ",
      thead: [
        "Product",
        "Max Grain Size mm",
        "Al2O3 %mm",
        "Fe2O3 %Max",
        "Dry Density g/cc",
        "CCS at 110 deg C /24 hrs",
        "PCEOC",
        "PLC%At oC/Hrs Max",
      ],
      tbody: [
        [
          "Champcast-A",
          "5",
          "88",
          "0.80",
          "2.75",
          "600",
          "37",
          "±1.00 at 1550oC/3 hrs"
        ],
        [
          "Champcast 90-Spl ",
          "5",
          "90",
          "0.80",
          "2.85",
          "800",
          "37",
          "±1.00 at 1550oC/3 hrs"
        ],
        [
          "Champcast-K",
          "5",
          "60",
          "1.00",
          "2.20",
          "350",
          "31",
          "±1.50 at 1550oC/3 hrs"
        ],
        [
          "Champcast-C",
          "5",
          "50",
          "1.30",
          "2.10",
          "350",
          "30",
          "±1.00 at 1550oC/3 hrs"
        ],
        [
          "Champcast-94",
          "5",
          "94",
          "0.30",
          "2.80",
          "600",
          "38",
          "±0.50 at 1550oC/3 hrs",

        ],
        [
          "Champcast-7",
          "5",
          "96",
          "0.30",
          "2.80",
          "400",
          "38",
          "±0.80 at 1550oC/3 hrs"
        ],


      ]
    },
    {
      title: 'MEDIUM PURITY DENSE CASTABLES ',
      thead: [
        "Product",
        "Max Grain Size mm",
        "Al2O3 %mm",
        "Fe2O3 %Max",
        "Dry Density g/cc",
        "CCS at 110 deg C /24 hrs ",
        "PCEOC",
        "PLC% At oC/Hrs Max"
      ],
      tbody: [
        [
          "ChampCrete-N",
          "5",
          "45",
          "4.00",
          "2.10",
          "250",
          "20",
          "±1.00 at 1350oC/3 hrs"
        ],
        [
          "ChampCrete-Spl ",
          "5",
          "45",
          "4.00",
          "2.20",
          "400",
          "16",
          "±1.00 at 1350oC/3 hrs"
        ],
        [
          "Champcast-K",
          "20",
          "40",
          "3.00",
          "2.20",
          "400",
          "16",
          "±1.00 at 1350oC/3 hrs"
        ],
        [
          "ChampCrete-Super",
          '5',
          '70',
          '5.00',
          '2.50',
          "350",
          '31',
          "±1.00 at 1400oC/3 hrs"
        ]
      ]
    },
    {
      title: 'FIRE CLAY AND HIGH ALUMINA CASTABLES',
      thead: [
        "Products",
        "Al2O3%",
        "Fe2O3%",
        "B.D gm/cc",
        "Cold Crushing Strength Dried at 110oC /24 hrs",
        "Cold Crushing Strength Fired at 1400oC /3 hrs",
        "Cold Crushing Strength Fired at 1500oC /3 hrs",
        "PLC %",
        "PCE (oC)",
        "Grain Size (mm)"
      ],
      tbody: [
        [
          "Champcast-FH-14",
          "40",
          "2.5",
          "1.9",
          "250",
          "350",
          "-",
          '±1.5 (1400oC/3h)',
          "1580",
          "0-5"
        ],
        [
          "Champcast-FH-45",
          "45",
          "4",
          "2.1",
          "250",
          "350",
          "-",
          "±1.0 (1400oC/3h)",
          "1580",
          "0-5"
        ],
        [
          "Champcast-FH-70",
          "70",
          "5",
          "2.5",
          "350",
          "450",
          "-",
          "±1.0 (1400oC/3h)",
          "1680",
          "0-5"
        ],
        [
          "Champcast-FH-50",
          "50",
          "1.5",
          "2.1",
          "350",
          "250",
          "400",
          "±1.0 (1500oC/3h)",
          "1660",
          "0-5",

        ],
        [
          "Champcast-FH-50 PLUS",
          "50",
          "1",
          "2.1",
          "500",
          "350",
          "500",
          "±1.0 (1500oC/3h)",
          "1700",
          "0-5"
        ],
        [
          "Champcast-FH-60",
          "60",
          "1",
          "2.2",
          "350",
          "250",
          "450",
          '±1.5 (1500oC/3h)',
          "1680",
          '0-5'
        ],
        [
          "Champcast-FH-60 PLUS",
          "60",
          "1",
          "2.2",
          "500",
          "350",
          "500",
          "±1.0 (1500oC/3h)",
          "1760",
          "0-5",

        ],
        [
          "Champcast-FH-70",
          "70",
          "1.5",
          '2.5',
          "500",
          "300",
          "550",
          "±1.5 (1500oC/3h)",
          "1760",
          "0-5"
        ],
        [
          "Champcast-FH-80",
          "80",
          "1.5",
          "2.6",
          "550",
          "300",
          "550",
          "±1.5 (1500oC/3h)",
          "1780",
          "0-5"
        ],
        [
          "Champcast-FH-90",
          "88",
          "1.5",
          "2.75",
          "550",
          "300",
          "550",
          "±1.5 (1500oC/3h)",
          "1780",
          "0-5"
        ],
        [
          "Champcast-FH-90 PLUS",
          "90",
          "1.0",
          "2.8",
          "600",
          "300",
          "550",
          "±1.5 (1550oC/3h)",
          "1800",
          "0-5"
        ],

      ]
    },
    {
      title: "INSULATION CASTABLES",
      thead: [
        "Product",
        "Max Grain Size (mm)",
        "Al2O3%",
        "Fe2O3%",
        "Dry Density kg/cc",
        "CCS at 110oC/24 hrs",
        "PCE (oC)",
        "PLC % at oC/ hrs Max"
      ],
      tbody: [
        [
          "Champcast-Insul-4",
          "6",
          "30",
          "11.00",
          "550",
          "4",
          "-",
          "±0.4800/3 HRS"
        ],
        [
          "Champcast-Insul-9",
          "6",
          "30",
          "6.00",
          "1000",
          '15',
          "14",
          "±0.6800/3hrs"
        ],
        [
          "Champcast-Insul-7",
          "6",
          "30",
          "8.50",
          "850",
          "12",
          "12",
          "±0.8800/3hrs"
        ],
        [
          "Champcast-Insul-11",
          "6",
          "30",
          "3.50",
          "1250",
          "35",
          "14",
          "±0.21100/3 hrs"
        ],
        [
          "Champcast-Insul-13",
          "6",
          "35",
          "3.50",
          "1450",
          "50",
          "14",


          "±0.21100/3 hrs"
        ],
        [
          "Champcast-Insul-15",
          "6",
          "35",
          "3.20",
          "1600",
          "90",
          "15",
          "±0.21100/3 hrs"
        ],
        [
          "Champcast-Insul-11 Li",
          "6",
          "40",
          "1.50",
          "1250",
          "45",
          "14",
          "±0.21100/3 hrs"
        ],
        [
          "Champcast-Insul-13 Li",
          "6",
          "40",
          "1.50",
          "1450",
          "80",
          "15",
          "±0.21100/3 hrs"
        ],
        [
          "Champcast-Insul-15 Li",
          "6",
          "40",
          "1.50",
          "1600",
          "130",
          "15",
          "±0.21100/3 hrs"
        ]
      ]
    },
    {
      title: "LOW CEMENT CASTABLES",
      thead: [
        "Products",
        "Max Grain Size (mm)",
        "Al2O3%",
        "Fe2O3%",
        "Dry Density kg/cc",
        "CCS at 110oC/24 hrs",
        "PCE (oC)",
        "PLC % at oC/ hrs Max"
      ],
      tbody: [
        [
          "Champcast-LC-45",
          "6",
          "45",
          "1.00",
          "2.30",
          "700",
          "32",
          "±1.001500/3 HRS"
        ],
        [
          "Champcast-LC-60",
          "6",
          "60",
          "1.50",
          "2.60",
          "750",
          "36",
          "±1.001500/3 HRS"
        ],
        [
          "Champcast-LC-70",
          "6",
          "70",
          "1.50",
          "2.70",
          "750",
          "36",
          "±1.001550/3 HRS"
        ],
        [
          "Champcast-LC-80",
          "6",
          "80",
          "1.80",
          "2.90",
          "800",
          "37",
          "±1.001550/3 HRS"
        ],
        [
          "Champcast-LC-90",
          "6",
          "90",
          "1.00",
          "3.00",
          "950",
          "37",
          "±1.001550/3 HRS"
        ],
        [
          "Champcast-LC-95",
          "6",
          "94",
          "0.30",
          "3.10",
          "1000",
          "38",
          "±1.001550/3 HRS"
        ]
      ]
    },
    {
      title: " CASTABLES FOR SPONGE IRON KILN",
      thead: [
        "Products",
        "B.D gm/cc 110oC /24 hrs",
        "CCS(Kg/Cm2) Dried at 110oC /24 hrs",
        "CCS(Kg/Cm2) 800oC /3 hrs",
        "CCS(Kg/Cm2) Fired at 1100oC /3 hrs",
        "CCS(Kg/Cm2) 1400oC /3 hrs",
        "PLC % 1100oC /3 hrs",
        "PLC (%)",
        "Grain Size (mm)"
      ],
      tbody: [
        [
          "Champcast-LC-45 SI",
          "2.2",
          "500",
          "600",
          "700",
          "800",
          "±0.3",
          "±0.5 1400oC/3 HRS",
          "0-5"
        ],
        [
          "Champcast-LC-60 SI",
          "2.3",
          "500",
          "600",
          "700",
          "800",
          "±0.3",
          "±0.5 1400oC/3 HRS",
          "0-5"
        ],
        [
          "Champcast-LC-62 MSI ",
          "2.3",
          "600",
          "700",
          "800",
          "900",
          "±0.3",
          "±0.5 1400oC/3 HRS",
          "0-5"
        ],
        [
          "Champcast-LC-70 SI",
          "2.4",
          "600",
          "700",
          "800",
          "900",
          "±0.3",
          "±0.5 1400oC/3 HRS",
          "0-5"
        ],
        [
          "Champcast-LC-80 SI",
          "2.5",
          "700",
          "800",
          "900",
          "1000",
          "±0.3",
          "±0.5 1400oC/3 HRS",
          "0-5"
        ],
        [
          "Champcast-AH-50 SI",
          "2.1",
          "300",
          "200",
          "150",
          "400",
          "±0.5",
          "±1.0 1400oC/3 HRS",
          "0-5"
        ],
        [
          "Champcast-11 SI",
          "1.25 max",
          "40",
          "30",
          "40",
          "-",
          "±0.8",
          "±1.0 1400oC/3 HRS",
          "0-5"
        ]
      ]
    },
    {
      title: "FLOW CONTROL MONOLITHICS",
      thead: [
        "Product",
        "Al2O3 % mm",
        "Grading (mm)",
        "Application Area"
      ],
      tbody: [
        [
          "CC-95M",
          "90",
          "0-0.2",
          "Jointing Mortar for Slide Plate & Nozzle",
        ],
        [
          "CC-95M",
          "94",
          "0-5",
          "Ramming mass for Fixing well Block"
        ]
      ]
    }
  ]


  const AcidandHeatResistant2 = [
    {
      title: "EPOXY",
      point: [
        "It is a Phenolic resin based, with silica filler, cold curing acid resistance cement. The advantage of this product is that it has good resistance towards acid and solvents.",
        "EPOXY carries ISI marking and conforms to ASTM C-395-80 and IS 4832 Part II."
      ],
      Uses: [
        "EPOXY is used for setting acid proof tiles / bricks. Various areas like floors, drains, neutralization pits, storage tanks, reaction vessels, filter notches, DM water plants are covered with brick lining using EPOXY Mortar.",
        "EPOXY Mortar is extensively used in industries like Dyestuff, Rayon, Metal Finishing, Fertilizers, Petrochemicals, etc.",
        "EPOXY is used for bedding and jointing of acid proof tiles / bricks when exposed to severe corrosive condition as prevailing in process tank / reaction vessels etc. for spillage conditions, EPOXY is used as pointing cement along with silicate mortar as the bedding cement."
      ]
    },
    {
      title: "BITUMASTIC",
      point: [
        "Bitumastic consist of selected acid resistant siliceous fillers and bitumen, Blended homogeneously to form a butter like consistent mastic."
      ],
      Uses: [
        "Bitumasticis employed as an exposed lining for corrosion resistant floors where traffic is light or non-existent. It is employed as an impermeable chemical resistant membrane on floors, channels, manholes, sumps etc., which are constructed out of concrete and brick masonry. Bitumastic is used on vertical surface upto 600mm height."
        , "This is used in different industries like Dyes, Pigments, Chemicals, Automobiles, Petroleum and Petrochemicals, Fertilizers, Sulphuric, Phosphoric Acid plants etc."
      ]
    },
    {
      title: "PRIMER",
      point: [
        "Primer is bitumen based corrosion resistant paint. It is a single component air curing paint. It is easily applied by brush; roller or spray and it meet the requirements of IS 9862 specification."
      ],
      Uses: [
        "Primer is used as primer over concrete surface for the application of mastic as membrane for the chemical resistant acid proof Tile / Brick Lining work on treatment with Primer, a good bond is developed between the concrete surface and the mastic.",
        "It is also used as anti-corrosive paint on steel structures for rust prevention. It is used for coating and protecting any surface, whether wood, metal or concrete in such constructions as bridges, tanks, girders, pipes, railways, docks, ship’s interiors and exteriors such as holds, bunders, peaks, decks, funnels, top sides, iron gearing and fittings etc. Further uses are for painting gas works, power stations and smoke stacks.",
        "Primer form an elastic film which expands / contracts with the metal to which it adheres and is therefore not subjected to “Flaking” making in an ideal coating for exposed conditions."
      ]
    }
  ]


  // rummyimg
  const RummyGuinning = [
    {
      title: "BASIC RAMMING MASSES",
      thead: [
        "Product",
        "MgO %",
        "SiO2 %",
        "Fe2O3 %",
        "Setting Type",
        "Grading (mm)",
        "Sintering Temp. (oC)",
        "Appli. Temp. (oC)",
        "Application Area"
      ],
      tbody: [
        [
          "CC-BRM-95",
          "94",
          "1.50",
          "-",
          "Chemical",
          "0-5",
          "1550",
          "1750",
          "Tap Hole of BOF"
        ],
        [
          "CC-BRM-85",
          "85",
          "5.00",
          "6.00",
          "Ceramic",
          "0-8",
          "1400",
          "1750",
          "Dry RAmming Mass for EAF Bottom"
        ],
        [
          "CC-EBT-45",
          "45",
          "-",
          "-",
          "-",
          "2-6",
          "-",
          "1750",
          "EBT-EAF Tap Hole Filling Mass"
        ],
        [
          "CC-WRM-84",
          "83",
          "8.50",
          "-",
          "Chemical",
          "0-5",
          "1550",
          "1750",
          "Wet Ramming Mass for EAF"
        ],
        [
          "CC-BRM-70",
          "70",
          "8.00 Cr2O3",
          "-",
          "Chemical",
          "0-5",
          "800",
          "1750",
          "Dry Ramming Mass for Induction Furnace Melting mild steel & alloy steel"
        ]
      ]
    },
    {
      title: "SILICA RAMMING MASSES",
      thead: [
        "Product",
        "SiO2 %",
        "Fe2O3 %",
        "Al2O3 %",
        "Setting",
        "Grading (mm) ",
        "PCE (SK)",
        "Sintering Temp. (oC)",
        "Appli. Temp. (oC)",
        "Application Area"
      ],
      tbody: [
        [
          "CC-SRM",
          "98",
          "0.3",
          "-",
          "Ceramic",
          "0-5",
          "32",
          "1200",
          "1700",
          "Lining Coreless Induction Furnace"
        ],
        [
          "CC-SRM",
          "98",
          "0.2",
          "0.9",
          "Ceramic",
          "0-5",
          "32",
          "1200",
          "1700",
          "Lining Coreless Induction Furnace"
        ]
      ]
    },
    {
      title: "BASIC GUNNING MASSES",
      thead: [
        "Product",
        "MgO %",
        "SiO2 %",
        "Setting",
        "Grading (mm) ",
        "PCE(SK)",
        "After drying at 110oC B.D (gm/cc) Min",
        "After drying at 110oC CCS (kg/cm2) Min",
        "Appli. Temp. (oC) PLC (%) Max",
        "Appli. Temp. (oC) CCS (kg/cm2) Min"
      ],
      tbody: [
        [
          "CC-GUN-1",
          "80",
          "9.0",
          "Chemical",
          "0-3",
          "38",
          "-",
          "-",
          "-2.0",
          "200"
        ],
        [
          "CC-GUN",
          "85",
          "8.0",
          "Chemical",
          "0-3",
          "38",
          "2.70",
          "350",
          "-1.5",
          "300"
        ],
        [
          "CC-GUN-SUPER",
          "90",
          "4.0",
          "Chemical",
          "0-3",
          "38",
          "2.75",
          "350",
          "-1.5",
          "200"
        ],
        [
          "CC-GUN-SUPER-SPL",
          "94",
          "2.0",
          "Chemical",
          "0-3",
          "38",
          "2.80",
          "400",
          "-1.5",
          "200"
        ]
      ]
    },
    {
      title: "BASIC SPRAYING MIXES",
      thead: [
        "Product",
        "MgO %",
        "SiO2 %",
        "Fe2O3 %",
        "Setting Type",
        "Grading (mm) ",
        "Sintering Temp. (oC)",
        "Appli. Temp. (oC)",
        "PLC (%)",
        "B.D (gm/cc)",
        "Application Area"
      ],
      tbody: [
        [
          "CC-SM/87-70",
          "66",
          "25",
          "5.5",
          "Chemical",
          "0-0.5",
          "750",
          "1600",
          "-3.0",
          "1.80",
          "Spraying of Bottom and walls of tundish"
        ],
        [
          "CC-SM/87-80",
          "78",
          "14",
          "5.0",
          "Chemical",
          "0-0.5",
          "750",
          "1650",
          "-5.0",
          "1.85",
          "Spraying of Bottom and walls of tundish"
        ],
        [
          "CC-SM/87-90",
          "85",
          "7",
          "3.0",
          "Chemical",
          "0-0.5",
          "750",
          "1650",
          "-5.0",
          "1.85",
          "Spraying of Bottom and walls of tundish"
        ],
        [
          "CC-SM/80",
          "78",
          "14",
          "5.0",
          "Chemical",
          "0-1",
          "750",
          "1650",
          "-1.5",
          "2.30",
          "Dry vibratable mix for bottom and wall of tundish"
        ]
      ]
    }
  ]


  // flowcontrol resisten
  const FlowControl = [
    {
      title: "SLIDE GATE REFRACTORIES",
      thead: [
        "Product",
        "A.P.* %",
        "B.D* (gm/cc)",
        "Al2O3 %",
        "Fe2O3 %",
        "Cr2O3 %",
        "MgO %",
        "F.C %",
        "ZrO2 %",
        "HMOR 1400oC (kg/cm2)",
        "Application Area"
      ],
      tbody: [
        [
          "SLIDE PLATE :"
        ],
        [
          "CC-SP-98-M",
          "17",
          "2.95",
          "-",
          "-",
          "-",
          "97",
          "-",
          "-",
          "-",
          "Continuous casting"
        ],
        [
          "CC-SP-85-CA",
          "16",
          "3.10",
          "84",
          "-",
          "-",
          "-",
          "4",
          "-",
          "-",
          "Ingot & Continuous casting"
        ],
        [
          "CC-SP-85-CAS",
          "16",
          "3.00",
          "80",
          "-",
          "-",
          "-",
          "4",
          "5.5",
          "-",
          "Continuous casting"
        ],
        [
          "CC-SP-85-CASS",
          "16",
          "3.0",
          "72",
          "-",
          "-",
          "-",
          "4",
          "9.0",
          "-",
          "Continuous casting"
        ],
        [
          "CC-AZC",
          "6",
          "3.00",
          "75",
          "-",
          "-",
          "-",
          "4",
          "9.0",
          "150",
          "Continuous casting"
        ],
        [
          "CC-SPSR",
          "8",
          "2.80",
          "-",
          "-",
          "-",
          "80",
          "-",
          "-",
          "100",
          "Continuous casting"
        ],
        [
          "NOZZLE"
        ],
        [
          "CC-SN-90-C",
          "20",
          "3.00",
          "88",
          "0.5",
          "3",
          "-",
          "-",
          "-",
          "-",
          "Nozzle for slide gate system"
        ],
        [
          "CC-SN-85-F",
          "20",
          "2.95",
          "84",
          "0.5",
          "-",
          "-",
          "-",
          "-",
          "-",
          "Nozzle for slide gate system"
        ],
        [
          "CC-SN-85-CA",
          "18",
          "2.95",
          "84",
          "-",
          "-",
          "-",
          "4",
          "-",
          "-",
          "Nozzle for slide gate system"
        ],
        [
          "CC-SN-90-CA",
          "12",
          "2.90",
          "90",
          "-",
          "-",
          "-",
          "2",
          "-",
          "-",
          "Nozzle for slide gate system"
        ],
        [
          "CC-SN-90-M",
          "12",
          "2.90",
          "-",
          "-",
          "-",
          "90",
          "5",
          "-",
          "-",
          "Nozzle for slide gate system"
        ],
        [
          "WELL BLOCK/SEATING BLOCK :"
        ],
        [
          "CC-SW-98-T",
          "-",
          "3.00",
          "98",
          "-",
          "-",
          "-",
          "-",
          "-",
          "-",
          "Well block/Seating Block"
        ],
        [
          "CC-SW-ULCC-75",
          "-",
          "2.75",
          "75",
          "0.7",
          "-",
          "-",
          "-",
          "-",
          "-",
          "Well block/Seating Block"
        ],
      ]
    },
    {
      title: "GAS PURGING REFRACTORIES",
      thead: [
        "Product",
        "A.P.* %",
        "B.D* (gm/cc)",
        "Al2O3 %",
        "Fe2O3 %",
        "MgO %",
        "Air Flow at 0.4 kg/cm2 Ltrs/min",
        "Application Area"
      ],
      tbody: [
        [
          "POROUS PLUG :"
        ],
        [
          "CC-PP (Part-1)",
          "18",
          "-",
          "88",
          "-",
          "-",
          "50",
          "Steel Ladle, LRF, VOD"
        ],
        [
          "CC-PP (Part-2)",
          "23-30",
          "-",
          "-",
          "-",
          "95",
          "-",
          "Steel Ladle, LRF, VOD"
        ],
        [
          "CC-MPP (1)",
          "23-30",
          "-",
          "-",
          "-",
          "95",
          "30",
          "Steel Ladle, LRF, VOD"
        ],
      ]
    }
  ]


  // TUNDISH CONTROL
  const Tundish_control = [
    {
      title: "TUNDISH REFRACTORIES",
      thead: [
        "Product",
        "A.P.* %",
        "B.D* (gm/cc)",
        "Al2O3 %",
        "Fe2O3 %",
        "ZrO2 %",
        "Application Area"
      ],
      tbody: [
        [
          "NOZZLE :"
        ],
        [
          "CC-ZRN-60",
          "20",
          "3.60",
          "-",
          "-",
          "58",
          "Tundish"
        ],
        [
          "CC-ZRN-66",
          "20",
          "3.65",
          "-",
          "-",
          "65",
          "Tundish"
        ],
        [
          "CC-ZRN-75",
          "20",
          "3.85",
          "-",
          "-",
          "74",
          "Tundish"
        ],
        [
          "SEATING BLOCK :"
        ],
        [
          "CC-70-B",
          "21",
          "2.60",
          "69",
          "3.5",
          "-",
          "Seating Block for Zircon/Zirconia Nozzle"
        ]
      ]
    }
  ]


  // SPECIAL REFACTORIE
  const Special_refactory = [
    {
      title: "SILICON CARBIDE BRICKS",
      thead: [
        "Product",
        "SiC %",
        "Al2O3 %",
        "Fe2O3 %",
        "B.D* (gm/cc)",
        "A.P.* %",
        "CCS (kg/cm2)",
        "HMOR at 1350oC (kg/cm)",
        "Thermal Conductivity 1000oC W/mk",
        "RUL oC",
        "Application Area"
      ],
      tbody: [
        [
          "CC-SiC",
          "83",
          "2.0",
          "1.00",
          "2.50",
          "16",
          "700",
          "175",
          "14.7",
          "1720",
          "Posts in Kiln cars, shape for Ferro alloy industry"
        ],
      ]
    },
    {
      title: "ZIRCON BRICKS",
      thead: [
        "Product",
        "ZrO2 %",
        "Al2O3 %",
        "B.D* (gm/cc)",
        "A.P.* %",
        "CCS (kg/cm2)",
        "RUL oC",
        "Application Area"
      ],
      tbody: [
        [
          "CC-ZR",
          "62",
          "-",
          "3.30",
          "20",
          "500",
          "1600",
          "For Bottom paving & other locations of Glass Tank Furnace"
        ],
        [
          "CC-ZMG",
          "18",
          "68",
          "3.10",
          "21",
          "500",
          "1650",
          "For Bottom paving & other locations of Glass Tank Furnace"
        ],

      ]
    }
  ]

  // ALL PRODUCT NAME
  const name = [
    "Basic Refractory Products",
    "Fire Clay & High Aluminiam",
    "Acid and Heat Resistant Products",
    "CASTABLES AND MONOLITHICS PRODUCTS",
    "Ramming&Gunning Spray Masses",
    "Slide Gate&Flow Control Refractories",
    "TUNDISH REFRACTORIES",
    "SPECIAL REFRACTORIES"
  ]

  // function for chang path
  const changePath = (name) => {
    window.location.pathname = `/product/${name}`
  }



  return (
    <>
      <Navbar />
      <ProductItemBanner img={mockProducts.find((item) => item.id == id).bannerimg} heading={id} />

      {/* ALL PRODUCTS */}
      <div className={`w-full mt-[70px] sm:flex-col sm:items-start sm:mt-[100px] sm:gap-[5px]  min-h-[100px] px-[20px]  flex items-center justify-center `}>
        {
          name.map((item) => {
            return (
              <>
                <div onClick={() => changePath(item)} className={`w-auto sm:p-2 cursor-pointer px-[5px] border h-full text-[15px] flex items-center justify-center font-bold ${id == item ? "bg-[#4c4c4c] text-white" : ""}`}>
                  {item}
                </div>
              </>
            )
          })
        }
      </div>
      <div className={`w-full min-h-[500px]`}>
        <div className={`w-full h-auto py-[20px] px-[30px] text-3xl`}>
          <h1 className={`fontchange w-auto h-auto`}>
            <span className={` w-auto h-auto border-b border-b-[#4c4c4c] py-[5px] pr-[10px]`}>
              {id}
            </span>
          </h1>

        </div>

        <div className={`w-full h-auto flex items-center justify-center flex-col py-[20px] px-[25px]`}>
          <div className={`w-full h-auto flex items-center justify-center flex-col`}>
            {id == "Fire Clay & High Aluminiam" && Fireclayandhighalumina.map((item) => {
              return (
                <>
                  <h3 className={` w-full h-auto  text-xl font-[600]`}>{item.title}</h3>
                  <table className={`w-full border border-[#ccc] mb-[50px] px-2`} >
                    <thead className={`text-left`}>
                      {
                        item.thead.map((head) => {
                          return (
                            <>
                              <th className={`bg-[#ccc] px-[10px]  h-[40px] `}>{head}</th>
                            </>
                          )
                        })
                      }
                    </thead>
                    <tbody>
                      {
                        item.tbody.map((singlerow) => {
                          return (
                            <>
                              <tr className={`hover:bg-[#cccccc46]`}>
                                {singlerow.map((cell) => {
                                  return (
                                    <>
                                      <td className={`px-[10px] border border-[#ccc] py-[5px]`}>{cell}</td>
                                    </>
                                  )
                                })}
                              </tr>
                            </>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </>
              )
            })}

            {
              id == "Basic Refractory Products" && RefactoryColumn.map((item) => {
                return (
                  <>
                    <h3 className={` w-full h-auto  text-xl font-[600]`}>{item.title}</h3>
                    <div className={`w-full h-auto sm:overflow-x-scroll`}>
                      <table className={`w-full border border-[#ccc] mb-[50px] px-2`} >
                        <thead className={`text-left`}>
                          {
                            item.thead.map((head) => {
                              return (
                                <>
                                  <th className={`bg-[#ccc] px-[10px]  h-[40px] `}>{head}</th>
                                </>
                              )
                            })
                          }
                        </thead>
                        <tbody>
                          {
                            item.tbody.map((singlerow) => {
                              return (
                                <>
                                  <tr className={`hover:bg-[#cccccc46]`}>
                                    {singlerow.map((cell) => {
                                      return (
                                        <>
                                          <td className={`px-[10px] border border-[#ccc] py-[5px]`}>{cell}</td>
                                        </>
                                      )
                                    })}
                                  </tr>
                                </>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </>
                )
              })
            }

            {id == "Acid and Heat Resistant Products" && acidandheatresistant.map((item) => {
              return (
                <>
                  <h3 className={` w-full h-auto  text-xl font-[600]`}>{item.title}</h3>
                  <div className={`w-full h-auto sm:overflow-x-scroll`}>
                    <table className={`w-full border border-[#ccc] mb-[50px] px-2`} >
                      <thead className={`text-left`}>
                        {
                          item.thead.map((head) => {
                            return (
                              <>
                                <th className={`bg-[#ccc] px-[10px]  h-[40px] `}>{head}</th>
                              </>
                            )
                          })
                        }
                      </thead>
                      <tbody>
                        {
                          item.tbody.map((singlerow) => {
                            return (
                              <>
                                <tr className={`hover:bg-[#cccccc46]`}>
                                  {singlerow.map((cell) => {
                                    return (
                                      <>
                                        <td className={`px-[10px] border border-[#ccc] py-[5px]`}>{cell}</td>
                                      </>
                                    )
                                  })}
                                </tr>
                              </>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </>
              )


            })}

            {id == "Acid and Heat Resistant Products" && AcidandHeatResistant2.map((item) => {
              return (
                <>
                  <div className={`w-full px-[20px] py-[15px] text-left flex items-start justify-center flex-col`}>
                    <h2 className={`w-full h-full flex items-center font-bold text-xl justify-start p-1`}>{item.title}</h2>
                    {item.point.map((point) => {
                      return (
                        <>
                          <p className={`w-full flex items-start justify-center h-auto text-left p-2`}>
                            <span className={`w-auto h-auto text-xl text-red-500`}>*</span>
                            <p className={`text-[#4c4c4c] w-full flex items-center justify-start text-left`}>
                              {point}
                            </p>
                          </p>

                        </>
                      )
                    })}
                    {/* USES SECTION */}
                    <div className={`w-full h-auto px-[30px] flex items-center justify-start flex-col`}>
                      <h2 className={`w-full h-auto font-bold text-xl flex items-center justify-start`}>Uses</h2>
                      {item.Uses.map((uses) => {
                        return (
                          <>
                            <p className={`w-full flex items-center justify-center h-auto text-left p-2`}>
                              <span className={`w-auto h-auto text-xl text-red-500`}>*</span>
                              <p className={`text-[#4c4c4c] w-full flex items-center justify-start text-left`}>
                                {uses}
                              </p>
                            </p>
                          </>
                        )
                      })}
                    </div>
                  </div>

                </>
              )
            })}


            {
              id == "CASTABLES AND MONOLITHICS PRODUCTS" && CASTABLES.map((item) => {
                return (
                  <>
                    <h3 className={` w-full h-auto  text-xl font-[600]`}>{item.title}</h3>
                    <div className={`w-full h-auto sm:overflow-x-scroll`}>
                      <table className={`w-full border border-[#ccc] mb-[50px] px-2`} >
                        <thead className={`text-left`}>
                          {
                            item.thead.map((head) => {
                              return (
                                <>
                                  <th className={`bg-[#ccc] px-[10px]  h-[40px] `}>{head}</th>
                                </>
                              )
                            })
                          }
                        </thead>
                        <tbody>
                          {
                            item.tbody.map((singlerow) => {
                              return (
                                <>
                                  <tr className={`hover:bg-[#cccccc46]`}>
                                    {singlerow.map((cell) => {
                                      return (
                                        <>
                                          <td className={`px-[10px] border border-[#ccc] py-[5px]`}>{cell}</td>
                                        </>
                                      )
                                    })}
                                  </tr>
                                </>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </>
                )
              })
            }

            {
              id == "Ramming&Gunning Spray Masses" && RummyGuinning.map(
                (item) => {
                  return (
                    <>
                      <h3 className={` w-full h-auto  text-xl font-[600]`}>{item.title}</h3>
                      <div className={`w-full h-auto sm:overflow-x-scroll`}>
                        <table className={`w-full border border-[#ccc] mb-[50px] px-2`} >
                          <thead className={`text-left`}>
                            {
                              item.thead.map((head) => {
                                return (
                                  <>
                                    <th className={`bg-[#ccc] px-[10px]  h-[40px] `}>{head}</th>
                                  </>
                                )
                              })
                            }
                          </thead>
                          <tbody>
                            {
                              item.tbody.map((singlerow) => {
                                return (
                                  <>
                                    <tr className={`hover:bg-[#cccccc46]`}>
                                      {singlerow.map((cell) => {
                                        return (
                                          <>
                                            <td className={`px-[10px] border border-[#ccc] py-[5px]`}>{cell}</td>
                                          </>
                                        )
                                      })}
                                    </tr>
                                  </>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </>
                  )
                })
            }


            {
              id == "Slide Gate&Flow Control Refractories" && FlowControl.map(
                (item) => {
                  return (
                    <>
                      <h3 className={` w-full h-auto  text-xl font-[600]`}>{item.title}</h3>
                      <div className={`w-full h-auto sm:overflow-x-scroll`}>
                        <table className={`w-full border border-[#ccc] mb-[50px] px-2`} >
                          <thead className={`text-left`}>
                            {
                              item.thead.map((head) => {
                                return (
                                  <>
                                    <th className={`bg-[#ccc] px-[10px]  h-[40px] `}>{head}</th>
                                  </>
                                )
                              })
                            }
                          </thead>
                          <tbody>
                            {
                              item.tbody.map((singlerow) => {
                                return (
                                  <>
                                    <tr className={`hover:bg-[#cccccc46]`}>
                                      {singlerow.map((cell) => {
                                        return (
                                          <>
                                            <td className={`px-[10px] border border-[#ccc] py-[5px]`}>{cell}</td>
                                          </>
                                        )
                                      })}
                                    </tr>
                                  </>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </>
                  )
                })
            }

            {
              id == "TUNDISH REFRACTORIES" && Tundish_control.map(
                (item) => {
                  return (
                    <>
                      <h3 className={` w-full h-auto  text-xl font-[600]`}>{item.title}</h3>
                      <div className={`w-full h-auto sm:overflow-x-scroll`}>
                        <table className={`w-full border border-[#ccc] mb-[50px] px-2`} >
                          <thead className={`text-left`}>
                            {
                              item.thead.map((head) => {
                                return (
                                  <>
                                    <th className={`bg-[#ccc] px-[10px]  h-[40px] `}>{head}</th>
                                  </>
                                )
                              })
                            }
                          </thead>
                          <tbody>
                            {
                              item.tbody.map((singlerow) => {
                                return (
                                  <>
                                    <tr className={`hover:bg-[#cccccc46]`}>
                                      {singlerow.map((cell) => {
                                        return (
                                          <>
                                            <td className={`px-[10px] border border-[#ccc] py-[5px]`}>{cell}</td>
                                          </>
                                        )
                                      })}
                                    </tr>
                                  </>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </>
                  )
                })
            }

            {
              id == "SPECIAL REFRACTORIES" && Special_refactory.map(
                (item) => {
                  return (
                    <>
                      <h3 className={` w-full h-auto  text-xl font-[600]`}>{item.title}</h3>
                      <div className={`w-full h-auto sm:overflow-x-scroll`}>
                        <table className={`w-full border border-[#ccc] mb-[50px] px-2`} >
                          <thead className={`text-left`}>
                            {
                              item.thead.map((head) => {
                                return (
                                  <>
                                    <th className={`bg-[#ccc] px-[10px]  h-[40px] `}>{head}</th>
                                  </>
                                )
                              })
                            }
                          </thead>
                          <tbody>
                            {
                              item.tbody.map((singlerow) => {
                                return (
                                  <>
                                    <tr className={`hover:bg-[#cccccc46]`}>
                                      {singlerow.map((cell) => {
                                        return (
                                          <>
                                            <td className={`px-[10px] border border-[#ccc] py-[5px]`}>{cell}</td>
                                          </>
                                        )
                                      })}
                                    </tr>
                                  </>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </>
                  )
                })
            }

          </div>
        </div>

      </div>





      <Footer />
    </>
  );
}

export default ProductItem;
