import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

import instagram from "../assests/imgs/instagram.png";
import facebook from "../assests/imgs/facebook.png";
import twitter from "../assests/imgs/twitter.png";
import logo from "../assests/imgs/InlineLogo.png";
import map from "../assests/imgs/placeholder.png";

import callIcon from "../assests/imgs/call.png";
import address from "../assests/imgs/global-network.png";

function Footer() {
  return (
    <footer>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={styles.flexC}>
            <div className={styles.logo}>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <p className={styles.center}></p>
          </div>
          <div className={styles.flexC}>
            <h3>Contact Info.</h3>
            <p className={`${styles.space} flex items-center justify-start gap-[5px]`}>
              <img src={address} alt="" className={styles.callIcon} />
              <b>Address:</b>
            </p>
            <span className={styles.fontW}>
              {" "}
              Industrial Area,Korba Road,Janjgir-Champa,
              <br />
              Chhattisgarh-495671, INDIA
            </span>

            <p className={`${styles.space} flex items-center justify-start gap-[5px]`}>
              <img src={callIcon} alt="" className={styles.callIcon} />{" "}
              <b>Phone:</b>
            </p>

            <span className={styles.fontW}> 91-7819-245693/245761</span>
            <span className={styles.fontW}> 91-7819-245761</span>
            <p className={`${styles.space} flex items-center justify-start gap-[5px]`}>
              ✉ <b>Email: </b>
            </p>

            <span className={styles.fontW}> championceramics@gmail.com</span>
            <span className={styles.fontW}>champion@championceramics.com</span>
            <div className={styles.googleMap}>
              <a
                href="https://www.google.com/maps/place/Champion+Ceramics+Pvt+Ltd./@22.041379,82.6667138,17z/data=!3m1!4b1!4m6!3m5!1s0x3a27bdc7817defa7:0xe830ed9cbff4d8bc!8m2!3d22.041374!4d82.6692887!16s%2Fg%2F11b6sjsdwb?hl=en&entry=ttu"
                className={`${styles.location} flex items-center justify-start gap-[5px]`}
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <img src={map} alt="map" />
                </span>
                <span className={styles.googleMapIcon}>Google Map</span>
              </a>
            </div>
          </div>
          <div className={styles.flexC}>
            <h3>Quick Links</h3>
            <div className={styles.flexRow}>
              <div className={styles.flex}>
                <Link to="/" className={styles.quickLink}>
                  <span className={styles.fontW}>Home</span>
                </Link>
                <Link to="/aboutus" className={styles.quickLink}>
                  <span className={styles.fontW}>About Us</span>
                </Link>
                <Link to="/infrastructure" className={styles.quickLink}>
                  <span className={styles.fontW}>Infrastructure</span>
                </Link>
                <Link to="/refactorapplication" className={styles.quickLink}>
                  <span className={styles.fontW}>Refactor Application</span>
                </Link>
              </div>
              <div className={styles.flex}>
                <Link to="/product" className={styles.quickLink}>
                  <span className={styles.fontW}>Product</span>
                </Link>
                <Link to="/ourteam" className={styles.quickLink}>
                  <span className={styles.fontW}>Our Team</span>
                </Link>
                <Link to="/ourclients" className={styles.quickLink}>
                  <span className={styles.fontW}>Our Client</span>
                </Link>
                <Link to="/contactus" className={styles.quickLink}>
                  <span className={styles.fontW}>Contact Us</span>
                </Link>
              </div>
            </div>
            <div className={`${styles.space1} flex items-center justify-center`}>
              <Link className={`${styles.logoLink} hover:scale-125 transition-all hover:transition-all`}>
                <img src={instagram} alt="instagram" />
              </Link>
              <Link className={`${styles.logoLink}  hover:scale-125 transition-all hover:transition-all `}>
                <img src={facebook} alt="facebook" />
              </Link>
              <Link className={`${styles.logoLink}  hover:scale-125 transition-all hover:transition-all `}>
                <img src={twitter} alt="twitter" />
              </Link>
              <div></div>
            </div>
          </div>
        </div>
        <div className={styles.copyright}>
          <span>
            © Copyright 2024 Champion Ceramics. All rights reserved. Design By
            TechCherry
          </span>
        </div>
      </div>
    </footer >
  );
}

export default Footer;
