import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";
import logo from "../assests/imgs/InlineLogo.png";
// import logo from "../assests/images/logo.jpg";

import search from "../assests/imgs/search-interface-symbol.png";
import menuImg from "../assests/imgs/hamburger-menu.svg";
import AppNav from "./AppNav";
import { useState } from "react";
import NavHead from "./NavHead";
import { ImCross } from "react-icons/im";
import { FaBars } from "react-icons/fa";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const [toggle, settoggle] = useState(false);
  const location = useLocation()

  // let pageYoffset = 0;
  // let prevwindowoffset = 0;
  // window.addEventListener("scroll", () => {
  //   prevwindowoffset = window.pageYOffset
  //   // if (pageYoffset > 200) {
  //   //   console.log(pageYoffset)
  //   //   document.getElementById("Main-nav").style.height = "0";
  //   // }
  //   console.log(pageYoffset, prevwindowoffset)

  //   pageYoffset = window.pageYOffset;
  //   return window.removeEventListener("scroll", () => { })
  // })

  return (
    <>
      {/* <div id="Main-nav" className={`fixed top-0 left-0 w-full h-auto z-20  `}> */}
      <NavHead />
      <nav className={`${styles.nav} sm:!flex sm:!items-center sm:!justify-end`}>
        <button onClick={() => settoggle(!toggle)} className={`hidden sm:!z-[50] sm:text-2xl sm:flex bg-white text-[#4c4c4c] fixed  right-[20px] top-[22px] p-1 rounded-md  items-center justify-center`}>
          {
            toggle ?
              <ImCross />
              : <FaBars />
          }
        </button>
        {/* <div className={styles.logo}>
          <Link to="/" className={styles.navbarLink}>
            <img src={logo} alt="logo" className={styles.logoImg} />
          </Link>
        </div> */}
        <ul className={`${styles.navbar} ${toggle ? ' sm:fixed sm:gap-[10px] sm:!w-[60%]   sm:h-full sm:top-0 sm:bg-white sm:flex sm:!items-end sm:!justify-start  sm:!pt-[70px] sm:flex-col' : ' sm:!hidden sm:flex-col'}`}>

          <Link to="/" className={`${styles.navbarLink} ${location.pathname == '/' ? " border-b-2 border-b-black  " : "hover:border-b-2 hover:border-b-black"}   `}>
            <li>HOME</li>
          </Link>
          <Link to="/aboutus" className={`${styles.navbarLink} ${location.pathname == '/aboutus' ? " border-b-2 border-b-black" : "border-b-2 border-b-transparent hover:border-b-2  hover:border-b-black"} `}>
            <li>ABOUT US</li>
          </Link>
          <Link to="/infrastructure" className={`${styles.navbarLink} ${location.pathname == '/infrastructure' ? " border-b-2 border-b-black" : "border-b-2 border-b-transparent hover:border-b-2  hover:border-b-black"}   `}>
            <li>INFRASTRUCTURE</li>
          </Link>
          <Link to="/refactorapplication" className={`${styles.navbarLink} ${location.pathname == '/refactorapplication' ? " border-b-2 border-b-black" : " border-b-2 border-b-transparent hover:border-b-2  hover:border-b-black"}  `}>
            <li>REFACTOR APPLICATION</li>
          </Link>
          <Link to="/product" className={`${styles.navbarLink} ${location.pathname == '/product' ? " border-b-2 border-b-black" : "border-b-2 border-b-transparent hover:border-b-2  hover:border-b-black"}   `}>
            <li>PRODUCT</li>
          </Link>
          <Link to="/ourteam" className={`${styles.navbarLink} ${location.pathname == '/ourteam' ? " border-b-2 border-b-black" : "border-b-2 border-b-transparent hover:border-b-2  hover:border-b-black"}   `}>
            <li>OUR TEAM</li>
          </Link>
          <Link to="/ourclients" className={`${styles.navbarLink} ${location.pathname == '/ourclients' ? " border-b-2 border-b-black" : "border-b-2 border-b-transparent hover:border-b-2  hover:border-b-black"}   `}>
            <li>OUR CLIENTS</li>
          </Link>
          <Link to="/contactus" className={`${styles.navbarLink} ${location.pathname == '/contactus' ? " border-b-2 border-b-black" : "border-b-2 border-b-transparent hover:border-b-2  hover:border-b-black"}   `}>
            <li> CONTACT US</li>
          </Link>


          {/* <div class={styles.dropdown}>
            <img src={search} alt="search" className={styles.searchLogo} />

            <div class={styles.dropdownContent}>
              <input type="text" placeholder="search" />
            </div>
          </div> */}
          {/* <div class={styles.dropdown}>
          <span>Mouse over me</span>
          <div class={styles.dropdownContent}>
            <p>Hello World!</p>
          </div>
        </div> */}
        </ul>

      </nav>
      {/* </div> */}
      {showMenu ? <AppNav setShowMenu={setShowMenu} /> : ""}
    </>

  );
}

export default Navbar;
