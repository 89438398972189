import Footer from "./Footer";
import Navbar from "./Navbar";
import Banner from "./Banner";

import styles from "./Profile.module.css";

import img from "../assests/UpdateSlider4.jpg";
import buildingLogo from "../assests/slider1_update.jpg";

function Profile() {
  return (
    <>
      <Navbar />
      <Banner heading="Profile" img={img} />
      <div className={styles.container}>
        <h3 className={`text-xl font-bold text-[#4c4c4c]`}>Who we are ?</h3>
        <div className={styles.box}>
          <img src={buildingLogo} alt="" />
        </div>
        <div className={styles.box}>
          <p>
            Champion Ceramics Pvt. Ltd., was established in the year 1987.
            Champion Ceramics Pvt. Ltd., is situated at a very strategic
            location of Central India namely CHAMPA (Chattisgarh) is located on
            the Howrah - Mumbai (via Nagpur) main line of South East Central
            Railway.
          </p>
          <p>
            From a humble beginning this unit has now grown into a large-modern
            works manufacturing:- Basic Refractory Products, Fireclay & High
            Alumina Bricks, Acid & Heat Resistance Bricks, Monolithics &
            Castables, Ramming, Gunning & Spray masses, Slide Gate / Flow
            Control Refractories, Tundish Refractories & Special Refractories
            for different categories of industries.
          </p>
          <p>
            The products of the company enjoy a high reputation for quality and
            durability and are in great demand all over the country.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Profile;
