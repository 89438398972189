import Navbar from "../components/Navbar";
import styles from "./InfraStructurePage.module.css";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import Performance from "../components/Performance";

import plantOverview1 from "../assests/images/plantoverview1-66547ba4ee746.webp";
import plantOverview2 from "../assests/images/plantoverview2-66547ba5cdf01.webp";
import plantOverview3 from "../assests/images/plantoverview3-66547bad32054.webp";
import bannerimg from "../../src/assests/UpdatePhoto/4.jpg"
import Labimg from "../assests/UpdatePhoto/5.jpg"
import RenderText from "../components/RenderTextAnimation/RenderText";
import { useInView } from "react-intersection-observer";

function InfraStructurePage() {
  const { inView, ref } = useInView({
    triggerOnce: true
  })

  const labname = [
    "Modulus of Rupture (MOR)",
    "CHEMICAL ANALYSIS",
    "COLD CRUSHING STRENGTH",
    "HMOR TESTING MACHINE",
    "REFACTORIES UNDERLOAD FURNACE",
    "SIEVE ANALYSIS",
    "MUFFLE FURNACE(1000C)"
  ]
  return (
    <>
      <Navbar />
      <Banner img={bannerimg} heading="INFRASTRUCTURE" />
      <div className={styles.infraStructureContent}>
        {/* <h2 className={`font-[500] text-[2rem]`} style={{ textAlign: "center" }}>
          <span className={`px-[20px] border-b-2 border-b-[#4c4c4c] `}>Infra-Structure</span>
        </h2> */}

        <RenderText>
          <p className={`${styles.paragraph} `}>
            <b>Champion Ceramics Pvt. Ltd.</b>, is situated at a very strategic
            location of Central India namely CHAMPA (Chhattisgarh) is located on
            the Howrah - Mumbai (via Nagpur)main line of South East Central
            Railway.
          </p>
        </RenderText>
        <p className={styles.paragraph}>
          The Distance from Raipur Airport to our Office is 191.3KMs(3.31 Min.)
          by Road and 163 KMs (2.51 Min.) by Train
        </p>
        <p className={styles.paragraph}>
          Champion Ceramics Pvt.Ltd., has been a leading manufacturer and
          supplier of refractory products. We Cater our products to following
          type of industries :-
        </p>

      </div>


      <div className={styles.infraStructureContent}>
        <h2 className={`font-[500] text-[1.5rem]`} style={{ textAlign: "left" }}>
          <span className={`border-b-2 text-left border-b-[#4c4c4c] pr-[20px]`}>PLANT OVERVIEW</span>
        </h2>
        <div className={`${styles.infraImg} !overflow-hidden sm:!justify-center !p-0`}>
          <RenderText>
            <div className={styles.box3}>
              <img src={plantOverview1} alt="workerImg" />
              <div className={styles.content3}>
                <h3>PLANT OVERVIEW</h3>
              </div>
            </div>
          </RenderText>

          <RenderText>
            <div className={styles.box3}>
              <img src={plantOverview2} alt="workerImg" />
              <div className={styles.content3}>
                <h3>PLANT OVERVIEW</h3>
              </div>
            </div>
          </RenderText>


          <div className={styles.box3}>
            <img src={plantOverview3} alt="workerImg" />
            <div className={styles.content3}>
              <h3>PLANT OVERVIEW</h3>
            </div>
          </div>

        </div>
      </div>



      <div className={styles.infraStructureContent}>
        <h2 className={`font-[500] text-[1.5rem]`} style={{ textAlign: "left" }}>
          <span className={`border-b-2 pr-[20px] border-b-[#4c4c4c]`}>
            LAB. TESTING FACILITIES

          </span>
        </h2>

        <div className={styles.infraImg}>
          <div className={`w-full min-h-[100px] px-[30px] flex-wrap py-[20px] flex items-start justify-start gap-[10px]`}>
            {
              labname.map((item, index) => {
                return (
                  <>
                    <RenderText>
                      <h1 className={`lineonhover w-auto cursor-pointer h-auto px-[15px] gap-2 font-[550] py-[12px] flex items-center justify-center text-[#4c4c4c]`}>
                        <span className={`px-2 text-xl font-bold bg-[#4c4c4c] text-white flex items-center justify-center rounded-md`} >
                          {index + 1}
                        </span>{item}</h1>
                    </RenderText>
                  </>
                )
              })
            }
          </div>

          <img src={Labimg} className={`w-full h-[280px] rounded-md`} />
        </div>
      </div>

      <Performance />
      <Footer />
    </>
  );
}

export default InfraStructurePage;
