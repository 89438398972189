import styles from "./AfterCarousel.module.css";

import requirement from "../assests/imgs/requirement.png";
import performance from "../assests/imgs/performance.png";
import reliability from "../assests/imgs/reliability.png";
import easyUse from "../assests/imgs/easy-use.png";
import support from "../assests/imgs/support.png";

function AfterCarousel() {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.box} sm:!h-auto sm:flex sm:items-center sm:justify-center sm:flex-col sm:!w-[75%]`}>
        <img src={`${requirement} `} className={`sm:!w-[150px] sm:!h-auto`} alt="requirement" />
        <h4 >Requirement Specific</h4>
        <p>
          All Champion Ceramics are site and requirement specific, and can be
          customised where required
        </p>
      </div>
      <div className={`${styles.box} sm:!h-auto sm:flex sm:items-center sm:justify-center sm:flex-col sm:!w-[75%]`}>
        <img src={performance} className={`sm:!w-[150px] sm:!h-auto`} alt="performance" />
        <h4>Enhanced Performance</h4>
        <p>
          All Champion Ceramics products have characteristics far superior to
          conventional products and enhance the value of entire projects
        </p>
      </div>
      <div className={`${styles.box} sm:!h-auto sm:!w-[75%] sm:flex sm:items-center sm:justify-center sm:flex-col`}>
        <img src={reliability} className={`sm:!w-[130px] sm:!h-auto`} alt="reliability" />
        <h4>Industrial Reliability</h4>
        <p>
          All Champion Ceramics products are rigorously tested to ensure a high
          level of reliability and repeatability at your site
        </p>
      </div>
      <div className={`${styles.box} sm:!h-auto sm:!w-[75%] sm:flex sm:items-center sm:justify-center sm:flex-col`}>
        <img src={easyUse} className={`sm:!w-[130px] sm:!h-auto`} alt="easyUse" />
        <h4>Ease of Use</h4>
        <p>
          All Champion Ceramics products will be easier to use and give better
          productivity than conventional materials
        </p>
      </div>
      <div className={`${styles.box} sm:!h-auto sm:!w-[75%] sm:flex sm:items-center sm:justify-center sm:flex-col`}  >
        <img src={support} className={`sm:!w-[130px] sm:!h-auto`} alt="support" />
        <h4>Support</h4>
        <p>
          All Champion Ceramics products come with guidance for usage. Where
          possible, we provide applicator agencies or undertake turnkey
          installations
        </p>
      </div>
    </div>
  );
}

export default AfterCarousel;
