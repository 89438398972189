import styles from "./Banner.module.css";
import defaultImg from "../assests/images/office2.webp";

function Banner({ heading, className, img = defaultImg, fontsize }) {
  return (
    <div className={`${styles.banner} animation-fade main-div !relative sm:!mt-[70px] sm:!h-[200px] ${className}`} style={{ backgroundImage: `url(${img})`, }}>
      <div className={`${styles.inside} z-20 sm:z-[19] `}>
        <h1 className={`${styles.typing} animation-fade !text-[${fontsize}] sm:!text-[22px] `} style={{ fontSize: `${fontsize}` }}>{heading}</h1>
      </div>
      <div className={`absolute w-full h-full top-0 left-0 bg-black opacity-40`}>
      </div>
    </div>
  );
}

export default Banner;
