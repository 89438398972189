import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import markerRetina from "leaflet/dist/images/marker-icon-2x.png";

import styles from "./CompanyLocationMap.module.css";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerRetina,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const CompanyLocationMap = () => {
  const position = [22.041374, 82.6692887]; // Replace with your company's coordinates

  return (
    <>
      <div
        className={styles.companyLocationMap}
        // style={{
        //   display: "flex",
        //   flexDirection: "row",
        //   alignItems: "center",
        // }}
      >
        <MapContainer
          center={position}
          zoom={13}
          style={{ height: "240px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.google.com/maps/place/Champion+Ceramics+Pvt+Ltd./@22.041379,82.6667138,17z/data=!3m1!4b1!4m6!3m5!1s0x3a27bdc7817defa7:0xe830ed9cbff4d8bc!8m2!3d22.041374!4d82.6692887!16s%2Fg%2F11b6sjsdwb?hl=en&entry=ttu" target="_blank" rel="noreferrer">GoogleMap</a> contributors'
          />

          <Marker position={position}>
            <Popup>
              <a
                href="https://www.google.com/maps/place/Champion+Ceramics+Pvt+Ltd./@22.041379,82.6667138,17z/data=!3m1!4b1!4m6!3m5!1s0x3a27bdc7817defa7:0xe830ed9cbff4d8bc!8m2!3d22.041374!4d82.6692887!16s%2Fg%2F11b6sjsdwb?hl=en&entry=ttu"
                target="_blank"
                rel="noreferrer"
                className={styles.underline}
              >
                <b>Champion Ceramics</b> <br /> Industrial Area, Korba Road,
                Janjgir-Champa, Chhattisgarh-495671, INDIA
              </a>
            </Popup>
          </Marker>
        </MapContainer>
        {/* <div className={styles.address}>
          <h3>
            OUR COMPANY <span>LOCATION</span>
          </h3>
          <span>Address:</span>
          <p>Industrial Area, Korba Road, Janjgir-Champa,</p>
          <p>Chhattisgarh-495671, INDIA</p>

          <span>Phone:</span>
          <p>+91-9999999999</p>
          <p>+91-9999999999</p>

          <span>Email:</span>
          <p>championceramics@gmail.com</p>
          <p>marketing.championceramics@gmail.com</p>
        </div> */}
      </div>
    </>
  );
};

export default CompanyLocationMap;
