import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./index.css";
import ScrollToTop from "./components/ScrollToTop";
import HomePage from "./pages/HomePage";
import InfraStructurePage from "./pages/InfraStructurePage";
import RefactorApplicationPage from "./pages/RefactorApplicationPage";
import ProductPage from "./pages/ProductPage";
import OurTeamPage from "./pages/OurTeamPage";
import OurClientPage from "./pages/OurClientPage";
import ContactusPage from "./pages/ContactusPage";
import AboutusPage from "./pages/AboutusPage";
import Profile from "./components/Profile";
import QualityMeasures from "./components/QualityMeasures";
import CompanyLocationMap from "./components/CompanyLocationMap";

import ProductLayout from "./pages/ProductLayout";
import ProductItem from "./components/ProductItem";
import OurTeamLayout from "./components/OurTeamLayout";
import PageNotFound from "./components/PageNotFound";
import Gallery from "./components/Gallery";
import IronSteelPlant from "./components/IronSteelPlant";
import PowerPlant from "./components/PowerPlant";
import CopperPlant from "./pages/CopperPlant";
import CementPlant from "./components/CementPlant";
import GlassPlant from "./components/GlassPlant";
import Aluminium from "./components/Aluminium";
import Navbar from "./components/Navbar";
import { useEffect } from "react";

function App() {


  return (
    <div>
      <BrowserRouter>

        <Routes>
          <Route index element={<HomePage />} />
          <Route path="aboutus" element={<AboutusPage />} />
          <Route path="infrastructure" element={<InfraStructurePage />} />
          <Route path="iron-and-steel-plant" element={<IronSteelPlant />} />
          <Route path="power-plant" element={<PowerPlant />} />
          <Route path="copper-plant" element={<CopperPlant />} />
          <Route path="cement-plant" element={<CementPlant />} />
          <Route path="glass-plant" element={<GlassPlant />} />
          <Route path="aluminium-plant" element={<Aluminium />} />
          <Route
            path="refactorapplication"
            element={<RefactorApplicationPage />}
          />
          <Route path="product" element={<ProductLayout />}>
            <Route index element={<ProductPage />} />
            <Route path=":id" element={<ProductItem />} />
          </Route>
          <Route path="ourteam" element={<OurTeamLayout />}>
            <Route index element={<OurTeamPage />} />
            <Route path="profile" element={<Profile />} />
            <Route path="quality-measures" element={<QualityMeasures />} />
          </Route>
          <Route path="ourclients" element={<OurClientPage />} />
          <Route path="contactus" element={<ContactusPage />} />
          <Route path="map" element={<CompanyLocationMap />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
