import Footer from "./Footer";
import Navbar from "./Navbar";

import alum1 from "../assests/images/alum1.jpg";
import alum2 from "../assests/images/alum2.jpg";
import alum3 from "../assests/images/alum3.jpg";
import alum4 from "../assests/images/alum4.jpg";
import alum5 from "../assests/images/alum5.jpg";
import alum6 from "../assests/images/alum6.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function Aluminium() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scroll(0, 0)
  }, [pathname])
  return (
    <>
      <Navbar />
      <div className={`mt-[150px] sm:mt-[80px] px-[25px] flex items-start justify-center flex-col`}>
        <h1 className={`w-full h-auto py-[20px] text-xl text-[#4c4c4c] font-bold`}>
          <span className={`w-auto h-auto border-b-2 border-b-[#4c4c4c] px-[5px]`}>
            Aluminium
          </span>
        </h1>
        <div className={` flex items-center justify-start w-full `} >
          <img src={alum1} className={`w-[300px] h-auto`} alt="" />
        </div>
        <div className={`py-[20px]`}>
          <p>
            Aluminium is the second most abundant metallic element in the
            earth’s crust after silicon & is remarkable for its low density and
            ability to resist corrosion. The transportation, construction &
            packaging industries consume the majority of this metal. Aluminum
            also has the advantage of a practically infinite capacity for
            recycling, making it a choice solution in terms of environmental
            protection.
          </p>
          <br />
          <p>
            Aluminium production is divided into two basic categories – primary
            & secondary production.
          </p>
          <p>
            Aluminium Industry is continuously working to increase productivity
            & to reduce cost through energy efficient & environment friendly
            smelting technology.
          </p>
          <p>
            CCPL offers a wide range of refractories for both primary &
            secondary production units.
          </p>
        </div>
        <div className={`px-[40px]`}>
          <h2 className={`text-xl text-[#4c4c4c] py-[10px] font-bold `}>Primary Smelting</h2>
          <li>Carbon Bake or Anode Baking Furnace (ABF).</li>
          <li>Rodding</li>
          <li>Potlining</li>
          <li>Hot Metal Ladle</li>
          <li>Melting & Holding Furnace</li>
          <li>Casting & Pouring Launder</li>
        </div>

        <div className={`w-full py-[20px]  `}>
          <h2 className={`text-xl font-bold text-[#4c4c4c] mb-[20px]`}>Primary Smelting Process</h2>
          <img className={` w-[400px] h-auto `} src={alum2} alt="" />
        </div>

        <div className={`w-full py-[20px] `} >
          <h2 className={`text-xl font-bold text-[#4c4c4c] mb-[20px]`}>Aluminium Casting Process</h2>
          <img className={` w-[400px] h-auto `} src={alum3} alt="" />
        </div>

        <div className={`w-full py-[20px]`}>
          <h2 className={`text-xl font-bold text-[#4c4c4c] mb-[20px]`}>Anode Baking Furnace</h2>
          <div className={`m-auto flex mb-[20px] items-start justify-center flex-col`}>
            <img className={` w-[700px] h-auto`} src={alum4} alt="" />
            <div className={`flex items-start justify-center flex-col w-[700px]`}>
              <p>1. Pit with anodes and packing coke</p>
              <p>2. Fluewall</p>
              <p>3. Sections</p>
              <p>4. Headwall</p>
              <p>5. Gas flow in the fluewalls</p>
              <p>6. Tub</p>
              <p>7. Insulating Bricks</p>
            </div>
          </div>
          <div className={``}>
            <p>
              Anode baking furnace consists of an even number of chambers or
              sections distributed in two parallel spans. Each section is
              separated from the following one by refractory partition-wall,
              which includes 6 to 10 flue walls and 5 to 9 cells or pits in
              which the anodes are charged and baked.
            </p>
            <p>
              CCPL has complete range of products for Anode Baking Furnace which
              covers:
            </p>
            <div className={`pl-[40px]`}>
              <li>Dense Firebricks</li>
              <li>Insulation Firebricks</li>
              <li>Mortars for dense & insulation bricks</li>
              <li>Dense & Insulating Castable</li>
              <li>Precast prefired shapes</li>
            </div>
          </div>
        </div>

        <div className={`flex items-center justify-center flex-col`}>
          <h2 className={`w-full flex items-center justify-start font-bold text-xl text-[#4c4c4c] py-[20px]`}>
            <span className={`w-auto h-auto border-b-2 py-[5px] pr-[10px] border-b-[#4c4c4c]`}>
              Rodding Shop
            </span>
          </h2>
          <div className={`py-[20px]`}>
            <img className={` w-[220px] h-auto mb-[20px]`} src={alum5} alt="" />
            <p>
              In Rodding shop the ROD which will act as a current conductor from
              Anode Ring bus to the Anode is sealed with the anode using molten
              cast iron. This process needs induction furnace for producing cast
              iron & Ladles or crucibles for transporting the hot metal from
              induction furnace to the anodes
            </p>
          </div>

          <div>
            <img className={` w-[220px] h-auto mb-[20px]`} src={alum6} alt="" />
            <p>
              CCPL has complete refractory solutions for the Equipment used in
              Rodding Shop.
            </p>
            <br />
            <p>
              Acidic Ramming Mass are extensively used as a working lining
              material in Induction Furnace with satisfactory performance.
            </p>
            <br />
            <p>
              Dense conventional castable – Castable 90 % is used as coil
              coating item in Induction Furnace & Castable 50 & 60 % is used as
              working lining in Ladle or crucible.
            </p>
            <br />
            <p>
              CCPL Castable 60 % is designed with high purity calcium aluminate
              cement alongwith superior quality calcined bauxite and Talochite.
              It has Low iron (&lt; 1.5%) alongwith early strength development
              characteristics. It is volume stable at application temperature &
              easy to apply either by casting or trowelling.
            </p>
            <br />
            <p className={`w-auto h-auto font-bold text-[#4c4c4c]`}>
              Pot Lining
            </p>
            <p>Pot is conventional name of the Aluminium reduction cell.</p>
            <p>The whole pot is lined with refractory and thermal insulation materials in a systematic way in order to make the pot thermally insulated and also to form as a cathode for the reduction process.</p>
            <p>CCPL offers a wide range of products for Pot Lining like- </p>

            <div className={`px-[25px] py-[20px]`}>
              <table className={`border border-[#ccc]`}>
                <thead className={`bg-gray-500 text-white`}>
                  <th>Product</th>
                  <th>CCPL Brand name</th>
                  <th>Special Features</th>
                </thead>
                <tbody>
                  <tr>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      Ordinary Refractory Brick
                    </td>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      CC 30 / CC 40
                    </td>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      Low Thermal Conductivity, High Dimensional Accuracy & Resistance towards molten aluminium & fluorinated sodium compounds.
                    </td>
                  </tr>
                  <tr>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      Jointing mortar
                    </td>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      CC 30 M / CC 40 M
                    </td>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      Good Workability
                    </td>
                  </tr>
                  <tr>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      Insulating Castable
                    </td>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      CC Incast 8S AL
                    </td>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      Low Bulk Density, Low Thermal Conductivity & High Compressive Strength
                    </td>

                  </tr>
                  <tr>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      High Strength Castable
                    </td>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      CC FH 13
                    </td>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      High Compressive Strength, Controlled sintering for easy removal at the end of use, Controlled setting to get sufficient working time.
                    </td>
                  </tr>

                  <tr>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      SiC blocks
                    </td>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      CC SiC-SN
                    </td>
                    <td className={`border sm:p-3 border-[#ccc]`}>
                      Good corrosion resistance against molten bath components & liquid metal, Low Thermal Expansion & High Strength
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Aluminium;
