import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import copper1 from "../assests/images/copper1.jpg";
import copper2 from "../assests/images/copper2.jpg";
import copper3 from "../assests/images/copper3.jpg";
import Fadeimg from "./FadeOnimg/Fadeimg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function CopperPlant() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scroll(0, -100)
  }, [])
  return (
    <>
      <Navbar />
      <div className={`mt-[150px] sm:mt-[90px]`}>
        <h1 className={`w-full h-auto flex items-center justify-center`}>
          <span className={`w-auto text-xl text-[#4c4c4c] h-auto px-[5px] py-[3px] border-b-2 border-b-[#4c4c4c]`}>
            COPPER INDUSTRY
          </span>
        </h1>
        <div className={`flex my-[25px] items-center sm:!flex-col justify-center gap-[25px]`}>
          <Fadeimg>
            <img className={`border sm:!h-[100px] sm:!w-[270px]`} src={copper1} alt="" />
          </Fadeimg>
          <Fadeimg>
            <img className={`border sm:!h-[100px] sm:!w-[270px]`} src={copper2} alt="" />
          </Fadeimg>
        </div>
        <div className={`px-[30px] py-[15px]`}>
          <p>
            CCPL provides world class refractories for copper industry which
            includes a wide range of direct bonded magnesia chrome (DBMC) bricks
            Fireclay / High alumina bricks.
          </p>
          <br />
          <p>
            CCPL manufactures DBMC bricks with high purity raw materials (sea
            water magnesia / high purity magnesia, fused mag. Chrome co-clinker
            & high purity chrome ore), using new generation mixers & presses and
            firing at temperature above
          </p>
          <br />
          <p>
            1800ºC to maximize direct bonding. A special post firing treatment
            has been designed for optimum spinel formation. These bricks are
            highly resistant to sulphate attack and have a strong resistance
            towards fayalite containing slag
          </p>
          <br />
          <p>
            Post firing treatment gives the material high room temp. strength
            and reduces permeability.
          </p>
          <br />
          <p>
            Copper is mainly produced by different processes like outokumpu,
            Mitsu bishi, ISA - SMELT etc. CCPL provides all refractories for
            these possesses.
          </p>
          <br />
          <p>
            DBMC bricks supplied by CCPL are performing satisfactorily in
            various equipments like flash smelting furnace, pierce smith
            converter, slag cleaning furnace, anode furnace, troff converter,
            smelting furnace, converting furnace, ISA Furnace, rotary holding
            furnace etc.
          </p>
        </div>
        <div className={`w-auto h-auto flex items-center justify-center flex-col my-[20px]`}>
          <img src={copper3} className={`sm:!w-[300px] sm:!h-[100px]`} alt="" />
          <h3 className={`w-auto h-auto font-[600]`}>Anode Furnace</h3>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CopperPlant;
