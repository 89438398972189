import Footer from "../components/Footer";
import Banner from "../components/Banner";
import styles from "./OurClientPage.module.css";

import Navbar from "../components/Navbar";
import img from "../assests/imgs/Picture1.webp";
import img2 from "../assests/imgs/Picture2.webp";
import img3 from "../assests/imgs/Picture3.webp";
import img4 from "../assests/imgs/Picture4.webp";
import img5 from "../assests/imgs/Picture5.webp";
import img6 from "../assests/imgs/Picture6.webp";
import img7 from "../assests/imgs/Picture7.webp";
import img8 from "../assests/imgs/Picture8.webp";
import img9 from "../assests/imgs/Picture9.webp";
import img10 from "../assests/imgs/Picture10.webp";


function OurClientPage() {

  const client = [
    {
      src: img2,
      name: "Vizag steel"
    },
    {
      src: img3,
      name: "Rail wheel factory"
    },
    {
      src: img4,
      name: " JSW"
    },
    {
      src: img5,
      name: 'Jindal STEEL & POWER'
    },
    {
      src: img6,
      name: 'Jindal Stainless'
    },
    {
      src: img7,
      name: 'Vedanta transforming element'
    },
    {
      src: img8,
      name: 'TATA Steel BSL limited'
    },
    {
      src: img9,
      name: 'ESL Steel Limited'
    },
    {
      src: img10,
      name: 'NALCO'
    },

  ]


  return (
    <>

      <Navbar />
      <Banner heading="Our Client" />
      <h2 className={`h-[60px] my-[10px] sm:text-xl sm:h-[40px] sm:ml-[30px]  flex items-center border-l-4 border-l-[red] pl-[10px] ml-[50px] justify-start font-bold text-2xl`}>Trusted Brand</h2>
      <div className={`${styles.infraStructureContent}   my-[10px] sm:!py-[60px] !py-[100px] !relative whitespace-nowrap flex items-center justify-center    `}>
        <div className="infinite-scroll-container overflow-hidden ">
          <div className="infinite-scroll-content gap-[30px] transition-all overflow-visible flex items-center justify-center">
            {client.concat(client).map((item, index) => (
              <div key={index} className="client-item hover:bg-[#ccc]">
                <img src={item.src} alt="Brand Logo" />
              </div>
            ))}
          </div>
        </div>
        <div className={`h-full w-[150px] bg-gradient-to-l sm:w-[100px] from-white absolute top-0 right-0`}></div>
        <div className={`h-full w-[150px] bg-gradient-to-r from-white sm:w-[100px] absolute top-0 left-0`}></div>
      </div>
      <Footer />
    </>
  );
}

export default OurClientPage;
