import { useRef, useState } from "react";
import styles from "./Enquiry.module.css";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

function EnquiryForm() {
  const formref = useRef()
  const [chech, setcheck] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    if (chech) {
      console.log('form submit')
      emailjs.sendForm('service_czpwwc6', 'template_qy5xjae', formref.current, {
        publicKey: 'E221jQ5d5qxBxogdc'
      }).then(() => alert('Message sent successfully')).catch(() => alert('Cant sent message'));
    } else {
      alert('Please select captcha')
      console.log('Please complete the CAPTCHA');
    }
  };

  const checkcaptcha = (value) => {
    console.log(value)
    setcheck(!chech)
  }

  return (
    <div className={styles.form}>
      <h1 className={`sm:text-lg text-3xl font-bold`}>
        <span>Qui</span>ck Enquiry
      </h1>
      <form ref={formref} className={styles.enquiryFrom}>
        <input placeholder="Enter Your Name" />
        <div className={styles.row}>
          <input placeholder="Email Adress" name="email" className={styles.inputClass} />
          <input placeholder="Phone Number" name="number" />
        </div>
        <textarea name="message" placeholder="Your message or Enquiry" />
        <div className={`w-full sm:!w-[150px] h-auto`}>
          <ReCAPTCHA
            sitekey="6LdE_FgaAAAAAMGo5HU3DffDVapagBVa-S3cgg7W"
            onChange={(value) => checkcaptcha(value)}
          />
        </div>
        <button className={styles.btn}>Send Message</button>
      </form>
    </div>
  );
}

export default EnquiryForm;
