import React from "react";
import styles from "./NoticeSlider.module.css";
import { Link } from "react-router-dom";

const NoticeSlider = () => {
  const notices = [
    { date: "2024-05-23", message: "Important update!" },
    { date: "2024-05-24", message: "Scheduled maintenance on Friday." },
    { date: "2024-05-25", message: "New feature coming soon!" },
    { date: "2024-05-26", message: "Office closed on public holiday." },
  ];

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  return (
    <div className={`${styles.container} !w-[35%] sm:!w-full`}>
      <h1 className={`text-3xl font-bold`}>
        <span>LAT</span>EST NEWS
      </h1>
      <div className={styles.noticeBoard}>
        <div className={styles.notices}>
          {notices.concat(notices).map((notice, index) => (
            <div key={index} className={styles.notice}>
              <Link className={styles.noticeLink}>
                {notice.message}
                <div className={styles.date}>{formatDate(notice.date)}</div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NoticeSlider;
