import styles from "./Gallery.module.css";

import image1 from "../assests/images/gallery1.webp";
import image2 from "../assests/images/gallery2.webp";
import image3 from "../assests/images/gallery3.webp";
import image4 from "../assests/images/gallery4.webp";
import image5 from "../assests/images/gallery5.webp";
import image6 from "../assests/images/gallery6.webp";
import image7 from "../assests/images/gallery7.webp";
import image8 from "../assests/images/gallery8.webp";
import image9 from "../assests/images/gallery9.webp";
import image10 from "../assests/images/gallery10.webp";
import image11 from "../assests/images/gallery11.webp";
import image12 from "../assests/images/gallery12.webp";
import Navbar from "./Navbar";
import ImageUpload from "./ImageUpload";
import Footer from "./Footer";

function Gallery() {
  return (
    <>
      <Navbar />
      <ImageUpload />
      <div className={styles.infraStructureContent}>
        <h2 style={{ textAlign: "center" }}>
          <span>Gallery</span>
        </h2>
        {/* <p>We have widest Product Basket which includes following items :-</p> */}
        <div className={styles.productContent}>
          <div className={styles.infraImg}>
            <div className={styles.box3}>
              <img src={image1} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image2} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image3} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image4} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image5} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image6} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image7} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image8} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image9} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image10} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image11} alt="ProductImg" />
            </div>
            <div className={styles.box3}>
              <img src={image12} alt="ProductImg" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Gallery;
