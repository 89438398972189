import React from 'react'
import "./Fadeimg.css"

const Fadeimg = ({ children }) => {
    return (
        <div className={`bgfade w-auto h-auto flex bg-center justify-center`}>
            {children}
        </div>
    )
}

export default Fadeimg