import Footer from "./Footer";
import Navbar from "./Navbar";

import cement from "../assests/images/cement.jpg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function CementPlant() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scroll(0, -100)
  }, [pathname])
  return (
    <>
      <Navbar />
      <div className={`mt-[150px] flex items-center sm:!mt-[100px] justify-center flex-col`}>
        <h1 className={`w-auto h-auto text-xl font-[600]`}>
          <span className={`w-auto h-auto border-b-2 border-b-[#4c4c4c] px-[10px]`}>
            CEMENT KILN
          </span>
        </h1>
        <div className={`py-[30px] flex items-center justify-center flex-col gap-[15px]`}>
          <img src={cement} className={`sm:!w-[300px] w-[300px] h-auto sm:!h-auto`} alt="" />
          <p className={`w-full text-center px-[20px]`}>
            CCPL has a wide range of HA bricks, Basic bricks and monolithics for
            different areas of cement kiln.All these bricks are manufactured by
            using high purity raw materials and processed by most modern
            manufacturing equipment.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CementPlant;
