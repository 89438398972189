import Banner from "../components/Banner";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import emailjs from '@emailjs/browser';
import styles from "./ContactusPage.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import carouselImg from "../assests/images/office3.webp";
import { useRef, useState } from "react";

function ContactusPage() {
  const [captchaValue, setCaptchaValue] = useState(null);
  const form = useRef()
  const handlecapchachange = async (value) => {
    setCaptchaValue(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (captchaValue) {
      console.log('form submit')
      emailjs.sendForm('service_czpwwc6', 'template_qy5xjae', form.current, {
        publicKey: 'E221jQ5d5qxBxogdc'
      }).then(() => alert('Message sent successfully')).catch(() => alert('Cant sent message'));
    } else {
      alert('Please select captcha')
      console.log('Please complete the CAPTCHA');
    }
  };

  return (
    <>
      <Navbar />
      <Banner heading="Contact Us" img={carouselImg} />
      <div className={styles.infraStructureContent}>
        {/* <h2 style={{ textAlign: "center" }}>
          <span className={` sm:text-xl sm:font-bold`}>Contact Us</span>
        </h2> */}
        <div className={styles.contact}>
          <div className={styles.left}>
            <h2 className={`!text-black font-[650] !text-xl`}>Get in Touch With Us</h2>
            <form ref={form} onSubmit={handleSubmit} className={styles.form}>
              <input name="name" className={` focus:border focus:border-[blue]`} placeholder="First Name" />
              <input className={` focus:border focus:border-[blue]`} placeholder="Last Name" />
              <input className={` focus:border focus:border-[blue]`} placeholder="Contact Number" />
              <input className={` focus:border focus:border-[blue]`} placeholder="Email" />
              <textarea className={` focus:border focus:border-[blue]`} name="message" placeholder="Message " />
              <ReCAPTCHA
                sitekey="6LdE_FgaAAAAAMGo5HU3DffDVapagBVa-S3cgg7W"
                onChange={handlecapchachange}
              />
              <Button>Submit</Button>
            </form>
          </div>
          {/* <hr /> */}
          <div className={`${styles.right} !pt-0 sm:w-full`}>
            <h2 className={`sm:font-bold font-[650] !text-xl !text-black `}>Contact Info</h2>

            <div className={`${styles.header} !text-black`}>
              <h6 className={` sm:font-[600] font-[600] !text-black`}>Corporate Office:</h6>
              <p className={`!text-black`}>
                Industrial Area, Korba Road, P.O. CHAMPA - 495671 Dist :
                Janjgir-Champa, Chhattisgarh, INDIA
              </p>
            </div>
            <div className={styles.header}>
              <h6 className={`sm:font-[600] font-[600] !text-black`}>Email: </h6>
              <div className={`${styles.row} !text-black sm:flex-col`}>
                <div className={`!text-black`}>
                  <p className={`!text-black`}>championceramics@gmail.com</p>
                  <p className={`!text-black`}>champion@championceramics.com</p>
                </div>
                <div className=" !text-black">
                  <p className={`!text-black`}>marketing.championceramics@gmail.com</p>
                  <p className={`!text-black`}>purchase.championceramics@gmail.com</p>
                </div>
              </div>
            </div>
            <div className={` sm:w-full h-[300px] sm:h-[300px]`}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3698.2148866536972!2d82.6692887!3d22.041373999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a27bdc7817defa7%3A0xe830ed9cbff4d8bc!2sChampion%20Ceramics%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1719474413617!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactusPage;
